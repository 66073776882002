import React, { useState, useEffect } from "react";
import './SearchBar.css'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';

const BrandsCategory = () => {
  const [brandData, setBrandData] = useState([]);
  const [dineData, setDineData] = useState([]);
  const { id } = useParams();
  const [titleFilter, setTitleFilter] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    async function fetchBrandData() {
      try {
        const response = await fetch('https://admin.phoenixmallofasia.com/api/brand');
        const jsonData = await response.json();
        // Add 'source' property for brand items
        const brandItems = jsonData.map(item => ({
          ...item,
          source: 'Brand',
          url: `https://www.phoenixmallofasia.com/brand/${item.title}`
        }));
        setBrandData(brandItems);
      } catch (error) {
        console.error('Error fetching brand data:', error);
      }
    }
    fetchBrandData();
  }, []);

  useEffect(() => {
    async function fetchDineData() {
      try {
        const response = await fetch('https://admin.phoenixmallofasia.com/api/dine');
        const jsonData = await response.json();
        // Add 'source' property for dine items
        const dineItems = jsonData.map(item => ({
          ...item,
          source: 'Dine',
          url: `https://www.phoenixmallofasia.com/dine/${item.title}`
        }));
        setDineData(dineItems);
      } catch (error) {
        console.error('Error fetching dine data:', error);
      }
    }
    fetchDineData();
  }, []);

  useEffect(() => {
    // Combine 'brand' and 'dine' data into a single array
    const combinedData = [...brandData, ...dineData];

    // Filter the combined data based on the titleFilter
    const filteredResults = combinedData.filter(item => {
      return titleFilter === '' || item.title.toLowerCase().includes(titleFilter.toLowerCase());
    });

    // Take the first three results
    const limitedResults = filteredResults.slice(0, 3);

    setSearchResults(limitedResults);
  }, [titleFilter, brandData, dineData]);

  const handleTitleFilter = (filterValue) => {
    setTitleFilter(filterValue);
  };

  return (
    <div className='container px-0'>
      <div className='input-wrap'>
        <i className="fas fa-search"></i>

        <input
          value={titleFilter}
          onChange={(e) => handleTitleFilter(e.target.value)}
          type="text"
          name="product-search"
          id="product-search"
          placeholder="Search Products"
        />
        <BiSearch className="search-iconnav" />


      </div >
      {titleFilter !== '' && (
        <div className="row px-md-0 px-2">
          {searchResults.map((item) => (
            <div className="col-md-12 itemcolor" key={item.id}>
              <hr />
              <a href={item.url} style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={item.photo1_path}
                  alt={item.title}
                  style={{ width: '50px', height: '50px', marginRight: '10px' }}
                />
                <div>
                  <h4>{item.title}</h4>
                  <p>{item.source}</p>
                </div>
              </a>
              <hr />
            </div>

          ))}
        </div>

      )}
    </div >

  );
};

export default BrandsCategory;
