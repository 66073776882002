import React, { Fragment, useState, useEffect } from "react";
import "./Brands.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import LaunchBanner from "../assets/Home/panache1.jpg";
import BrandsCategorymore from "../components/Brands/BrandsCategorymore";
import BrandHead from "../components/Brands/BrandHead";
import Form1 from "../components/Common/Form1";
import Brand1 from "../assets/Brands/brooks-logo.png";
import Image1 from "../assets/Brands/image1.png";
import { useParams } from 'react-router-dom'; // Import useParams
import axios from 'axios';

const BrandDetails = (props) => {
  const { id } = useParams(); // Use useParams to access the 'id' parameter
  const [brand, setBrand] = useState(null);


  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://admin.phoenixmallofasia.com/brand/${id}`);
        setBrand(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setBrand(null);
      }
    }

    fetchUserData();
  }, [id]);
  return (
    <Fragment>
      <div className="brand_detail">
        <Header />
        <Sidebar />
        {brand && (
          <>
            <Banner
              title={brand.title}
              image={brand.photo2_path}
              para={brand.desctext}

            />



            <BrandHead
              title={brand.title}
              floor={brand.floor}
              sub1={brand.floor}
              box="BRAND CATALOGUE"
              logo={brand.photo1_path}
              subtitle={brand.abouttitle}
              para={brand.about}
              t1={brand.contact}
              t2={brand.email}
              t3={brand.timing}
            />


            <div className="main-pad py-md-5 py-4">
              <div className="row mt-md-4">
                <div className="col-md-3 col-12">
                  <img src={brand.gallery1_path} />
                </div>
                <div className="col-md-3 col-12">
                  <img src={brand.gallery2_path} />
                </div>
                <div className="col-md-3 col-12">
                  <img src={brand.gallery3_path} />
                </div>
                <div className="col-md-3 col-12">
                  <img src={brand.gallery4_path} />
                </div>
              </div>
            </div>
          </>

        )}
        <div className="main-pad pe-md-0 pb-0 pb-md-0">
          <div className="d-flex mt-xl-4 mt-0">
            <h2> More Like This </h2>
            <hr />
          </div>
        </div>
      </div>
      <div className="py-md-5 pt-4">
      <BrandsCategorymore />
      </div>
      {/* <Nhance /> */}
      {/* <Notes /> */}
      <Form1 title="Want to know more about this brand?" />

    </Fragment>
  );
};

export default BrandDetails;
