import React, { Fragment } from "react";
import "./Luxury.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import BannerCarousel1 from "../components/Common/BannerCarousel1";
import Help from "../components/Home/Help";
import BrandsCategory from "../components/Brands/BrandsCategory";

const LuxuryCategory = (props) => {
  return (
    <Fragment>
      <div className="luxury_category">
        <Header />
        <Sidebar />
        <BannerCarousel1
          title1="BURBERRY"
          para1="Arriving soon"
          image1="img/launch/BB.jpg"
          title2="COACH"
          para2="Arriving soon"
          image2="img/launch/Co.jpg"
          title3="LOUIS VUITTON"
          para3="Arriving soon"
          image3="img/launch/LV.jpg"
        />
        <div className="culinary_adv1">
          {/*<div>
            <h4> A selection that <br/> leaves you spolit </h4>
            <h3> Choice</h3>
            <p> UPPER GROUND FLOOR </p>
  </div>*/}
        </div> 
        <div className="newluxspace" style={{ padding: '30px' }}>
      {/* Content of Section 1 */}
        </div>
        <BrandsCategory />     
        <Help />
      </div>
    </Fragment>
  );
};

export default LuxuryCategory;
