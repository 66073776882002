import React, { Fragment } from "react";
import Card3 from "../Common/Card/Card3";
import Brand1 from "../../assets/Brands/Tony-and-Guy.png";
import Brand2 from "../../assets/Brands/playsalon.jpg";
import Brand3 from "../../assets/Brands/eyecatcher.png";
import Carousel3 from "../Common/Carousel/Carousel3";

const LeisureSpa = (props) => {
  return (
    <Fragment>
      <div className="leisure_spa mt-md-4 mt-3">
        <div className="main-pad">
          <div className="row px-md-0 px-2">
            <div className="col-md-4 col-6 px-md-3 px-1">
              <a href="https://www.phoenixmallofasia.com/brand/Toni%20&%20Guy">
                <Card3
                  image={Brand1}
                  location="The Abcd Zone"
                  floor="First Floor"
                />
              </a>
            </div>
            <div className="col-md-4 col-6 px-md-3 px-1">
              <a href="https://www.phoenixmallofasia.com/brand/Play%20Salon">
                <Card3
                  image={Brand2}
                  location="The Abcd Zone"
                  floor="Ground Floor"
                />
              </a>
            </div>
            <div className="col-md-4 col-6 px-md-3 px-1">
              <a href="https://www.phoenixmallofasia.com/brand/Eyecatchers">
                <Card3
                  image={Brand3}
                  location="The Abcd Zone"
                  floor="Third Floor"
                />
              </a>
            </div>
          </div>
        </div>
        <Carousel3 image="img/Leisure/spa.png" />
      </div>
    </Fragment>
  );
};

export default LeisureSpa;
