import React, { Fragment } from "react";
import "./BannerVideo.css";
import Button4 from "./Button/Button4";

const BannerVideo = ({ id, title, para, video, link }) => {
  const addBanner = () => {
    dispatchEvent({
      item: {
        id: id,
        title: title,
        para: para,
        video: video,
        link: link,
      },
    });
  };
  return (
    <div className="banner_video">
      <Fragment>
        <video className="w-100" loop autoPlay muted>
          <source src={video} type="video/mp4" />
        </video>
        <div className="overlay"></div>
        <div className="main-pad">
        <div className="banner-text">
          <div className="row">
            <div className="col-md-8">
              <h1> {title} </h1>
              <p className="mt-md-4 mt-2">
                {para}
              </p>
            </div>
            <a href= {link} target="_blank" className="center-button">
          <Button4 title="Know More" />
        </a>
            {/* <div className="col-md-4">
              <div class="social-media">
                <Link href="">
                  {" "}
                  <BiLogoFacebook />{" "}
                </Link>
                <Link href="">
                  {" "}
                  <BiLogoTwitter />{" "}
                </Link>
                <Link href="">
                  {" "}
                  <BiLogoInstagram />
                </Link>
                <Link href="">
                  {" "}
                  <BiLogoYoutube />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
        </div>
      </Fragment>
    </div>
  );
};

export default BannerVideo;
