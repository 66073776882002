import React, { Fragment } from "react";
import "./Banner.css";
import Carousel from "react-bootstrap/Carousel";

const BannerCarousel = ({
  id,
  title,
  title1,
  title2,
  title3,
  title4,
  title5,
  image1,
  image2,
  image3,
  image4,
  image5,
  para1,
  para2,
  para3,
  para4,
  para5,
}) => {
  const addBanner = () => {
    dispatchEvent({
      item: {
        id: id,
        title: title,
        title1: title1,
        title2: title2,
        title3: title3,
        title4: title4,
        title5: title5,
        image1: image1,
        image2: image2,
        image3: image3,
        image4: image4,
        image5: image5,
        para1: para1,
        para2: para2,
        para3: para3,
        para4: para4,
        para5: para5,
      },
    });
  };
  return (
    <div className="banner">
      <Fragment>
        <Carousel autoplay>
          <Carousel.Item>
            <img src={image1} />
            <div className="overlay"></div>
            <div className="banner-text">
                <h1> {title1} </h1>
                <p> {para1} </p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img src={image2} />
            <div className="overlay"></div>
            <div className="banner-text">
                <h1> {title2} </h1>
                <p> {para2} </p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img src={image3} />
            <div className="overlay"></div>
            <div className="banner-text">
                <h1> {title3} </h1>
                <p> {para3} </p>
            </div>
          </Carousel.Item>
        </Carousel>
      </Fragment>
    </div>
  );
};

export default BannerCarousel;
