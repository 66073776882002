import React, { Fragment } from "react";

import "./responsive.css";
import "./Home.css";

import Header from "../components/Common/Navbar";
import Panache from "../components/Home/Panache";
import Inox from "../components/Home/Inox";
import Grandeur from "../components/Home/Grandeur";
import Categories from "../components/Home/Categories";
import Offers from "../components/Home/Offers";
import Events from "../components/Home/Events";
import Memoirs from "../components/Home/Memoirs";
import StayConnect from "../components/Home/StayConnect";
import Help from "../components/Home/Help";
import Nhance from "../components/Home/Nhance";
import InoxMob from "../components/Home/InoxMob";
import Sidebar from "../components/Common/NavbarMob";
import BannerVideo from "../components/Common/BannerVideo";
import BannerV from "../assets/Home/MOA Logo Reveal - website.mp4";
import Button1 from "../components/Common/Button/Button1";

const Home = (props) => {
  return (
    <Fragment>
      <Header />
      <Sidebar />
      <BannerVideo
        video={BannerV}
        link="https://events.phoenixmallofasia.com/"
        /*title="CALL OF INDULGENCE "
          para="Extravagance now has a place to call home. Welcome to Bangalore’s
              first of its kind premium luxury lifestyle destination – a retail
              atmosphere like no other. An exhilarating environment and mecca of
              shopping, dining and leisure. And that’s not all."*/
      />
      <Panache />
      <Grandeur />
      <Categories />
      {/*<Inox /> */}
      {/*<InoxMob /> */}
      {/*<Offers /> */}
      {/* <Nhance /> */}
      {/*<Events />*/}
      {/*Memoirs /> */}
      {/*<StayConnect image1="img/Home/events.jpg" />*/}
      <Help />
    </Fragment>
  );
};

export default Home;
