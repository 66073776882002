import React, { Fragment, useState } from "react";
import Axios from 'axios';

import "./Footer.css";
import Flogo from "../../assets/flogo.png";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";

function Footer() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleEmailSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    Axios.post('https://admin.phoenixmallofasia.com/api/subscribe', { email })
      .then((response) => {
        console.log(response.data);
        setEmail('');
        setError('');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <Fragment>
      <div className="footer py-md-5 py-4">
        <div className="pt-md-4 main-pad">
          <div className="row mb-md-4">
            <div className="col-md-3">
              <a href="/">
                <img className="mt-xl-4 mt-md-4 flogo w-75" src={Flogo} />
              </a>
            </div>
            <div className="col-md-6">
              <div className="row my-md-0 mt-4 mb-3">
                <div className="col-md-4 col-6">
                  {/*<a href="/luxury">
                    {" "}
                    <p> Luxury</p>
  </a>*/}
                  <a href="https://events.phoenixmallofasia.com/">
                    {" "}
                    <p> Events</p>
  </a>
                  <a href="/brands">
                    {" "}
                    <p> Brands</p>
                  </a>
                  <a href="/dine">
                    {" "}
                    <p> Dine </p>
                  </a>
                  {/*<a href="/events">
                    {" "}
                    <p> Events</p>
  </a>*/}
                  {/*<a href="/launch">
                    {" "}
                    <p> MOA ARRIVALS </p>
</a>*/}
                </div>
                <div className="col-md-4 col-6">
                  {/*<a href="/offers-packages">
                    {" "}
                    <p> Offers & Packages</p>
                  </a>*/}
                  <a href="/leisure">
                    {" "}
                    <p> Leisure</p>
                  </a>
                  <a href="/services">
                    {" "}
                    <p> Services</p>
                  </a>
                  {/*<a href="/gift">
                    {" "}
                    <p> Gift Card</p>
                  </a>*/}
                  {/*<a href="/blogs">
                    {" "}
                    <p> Blogs </p>
  </a>*/}
                </div>
                <div className="col-md-4">
                  <a href="/leasing">
                    {" "}
                    <p> Leasing</p>
                  </a>
                  <a href="/careers">
                    {" "}
                    <p> Careers </p>
                  </a>
                  <a href="/terms-conditions">
                    {" "}
                    <p> Terms & Conditions </p>
                  </a>
                  <a href="/about">
                    {" "}
                    <p> About Us</p>
                  </a>
                  {/*<a href="/news">
                    {" "}
                    <p> News </p>
</a>*/}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d69469.23781435397!2d77.59842533013425!3d13.069331564529337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1925ed3bc12f%3A0x7b731ab2850d5f8b!2sPhoenix%20Mall%20Of%20Asia!5e0!3m2!1sen!2sin!4v1694845837169!5m2!1sen!2sin"
                className="mb-md-3 mb-3 w-100"
                height="150"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <label>
                Sparkle one Mall Developers Pvt. Ltd. ,<br /> Phoenix Mall of
                asia Bangalore, Bellary Main Road,
                <br /> Batrayanpura,Bangalore North - 560094
              </label>
            </div>
          </div>
          <hr />
          <div className="subscribe d-flex">
            <h3>SUBSCRIBE TO OUR NEWSLETTER FOR OFFERS AND MORE</h3>
            <form onSubmit={handleEmailSubmit}>
              <div className="d-flex">
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email" required
                />
                <button type="submit"> <BsChevronRight /> </button>
              </div>
              {error && <p className="error-message">{error}</p>}
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Footer;
