import React, { Fragment } from "react";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import Location from "../components/Contact/Location";
import ContactForm from "../components/Contact/ContactForm";

const ContactUs = (props) => {
  return (
    <Fragment>
      <div className="contact_us">
        <Header />
        <Sidebar />
        <Banner image="img/contact-banner.jpg" title="CONTACT US" />
        <Location />
        <ContactForm />
      </div>
    </Fragment>
  );
};

export default ContactUs;
