import React, { useState, useEffect } from "react";
import "./DineCategory.css";
import Card3 from "../Common/Card/Card3";
import axios from 'axios';
import { useParams } from 'react-router-dom';

// Function to shuffle an array randomly
function shuffleArray(array) {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

const DineCategorymore = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [dine, setDine] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://admin.phoenixmallofasia.com/api/dine');
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://admin.phoenixmallofasia.com/dine/${id}`);
        setDine(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setDine(null);
      }
    }
    fetchUserData();
  }, [id]);

  // Filter the data to show only items with the same category as the selected dine
  const categoryToDisplay = dine ? dine.category : '';
  const filteredDine = data.filter((dine) => dine.category === categoryToDisplay && dine.title !== id);
  // console.log("filteredDine", filteredDine)

  // Shuffle the filtered data randomly 
  const shuffledDine = shuffleArray(filteredDine);

  return (
    <div className="brand_view main-pad">
      <div className="row px-md-0 px-2 brands_category">
        <div className="row px-md-0 px-2 ms-0">
          {shuffledDine.slice(0, 4).map((dine) => (
            <div className="col-md-3 col-6 px-md-3 px-1" key={dine.id}>
              <a href={`https://www.phoenixmallofasia.com/dine/${dine.title}`}>
                <Card3
                  category={dine.category}
                  image={dine.photo1_path}
                  location={dine.contact}
                  floor={dine.floor}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DineCategorymore;
