import React, { Fragment } from "react";
import "./Launch.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Carousel1 from "../components/Common/Carousel/Carousel1";
import Carousel4 from "../components/Common/Carousel/Carousel4";
import Collection from "../components/Launch/Collection";
import Cooking from "../components/Launch/Cooking";
import Form1 from "../components/Common/Form1";
import BannerCarousel from "../components/Common/BannerCarousel";

const Launch = (props) => {
  return (
    <Fragment>
      <div className="launch">
        <Header />
        <Sidebar />
        <BannerCarousel
          title4="MAX"
          para4="Visit MAX, 2nd Floor, Phoenix Mall of Asia."
          image4="img/launch/max.jpg"
          Link1="https://www.phoenixmallofasia.com/brand/"
          title3="Victoria's Secret"
          para3="NOW Open at Upper Ground Floor, Phoenix Mall of Asia."
  image3="img/launch/Zara.png "
          Link3="https://www.phoenixmallofasia.com/brand/"
          title2="SWAROVSKI"
          para2="NOW Open at Second Ground Floor, Phoenix Mall of Asia."
          image2="img/launch/Dune.jpg"
          Link2="https://www.phoenixmallofasia.com/brand/"
          title1="H&M"
          para1="Visit at 1st Floor, Phoenix Mall of Asia."
          image1="img/launch/H&M.png"
Link4="https://www.phoenixmallofasia.com/brand/"
        />
        <Carousel1
          title="MOA ARRIVALS"
          title1="Adidas Kids, 2nd Floor"
          image1="img/launch/adidaskids.jpg"
          Link1="https://www.phoenixmallofasia.com/brand/"
          title2="Gioia, Ground Floor"
          image2="img/launch/gioia.png"
          Link2="https://www.phoenixmallofasia.com/brand/"
          title3="Jo Malone, Upper Ground Floor"
          image3="img/launch/jomalone.png"
          Link3="https://www.phoenixmallofasia.com/brand/"
          title4="Label By Ritu Kumar, 1st Floor"
          image4="img/launch/lrk.png"
          Link4="https://www.phoenixmallofasia.com/brand/"
          image5="img/launch/bbw.png"
          title5="Bath & Body Works, 1st Floor"
          Link5="https://www.phoenixmallofasia.com/brand/"
        />
        <Collection />
        {/*<div className="coming_soon">
          <Carousel4
            title="COMING SOON"
            image1="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/09/Moaarraive-banner-3.png"
            image2="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/09/Moaarraive-banner-4.png"
            image3="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/11/Axorte-copy.jpg"
            image4="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/09/Senco.jpg"
          />
  </div>*/}
        <Cooking />
        <Form1
          title="Something Significant is on its Way to You"
          para="Write to us and we will reach out shortly."
        />
      </div>
    </Fragment>
  );
};

export default Launch;
