import React, { Fragment } from "react";
import "../../pages/EventMain.css";

const PastEvents = (props) => {
  return (
    <div className="past_events pb-md-5 pt-3 mt-md-4">
      <Fragment>
        <div className="main-pad">
          <div className="d-flex">
            <h2> EXPLORE OUR PAST EVENTS </h2>
            <hr/>
          </div>
          <div className="row my-md-5 my-4 w-100 ms-0">
            <div className="col-md-3 px-1 mb-2">
                <img src="img/Leasing/pe1.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
                <img src="img/Leasing/pe2.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
                <img src="img/Leasing/pe3.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
                <img src="img/Leasing/pe4.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
                <img src="img/Leasing/pe5.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
                <img src="img/Leasing/pe6.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
                <img src="img/Leasing/pe7.jpg" />
            </div>
            <div className="col-md-3 px-1 mb-2">
                <img src="img/Leasing/pe8.jpg" />
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default PastEvents;
