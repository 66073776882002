import React, { Fragment } from "react";
import "../../pages/EventMain.css";
import Card6 from "../Common/Card/Card6";

const EventCard = (props) => {
  return (
    <div className="event_card pt-md-5 pt-3 mt-md-4">
      <Fragment>
        <div className="main-pad">
          <div className="d-flex">
            <h2> COMING TO YOU THIS MONTH </h2>
            <hr />
          </div>
          <div className="row">
            <div className="col-md-4">
              <a href="/Rickeykej">
                <Card6
                  title="Rickey Kej Live In Concert"
                  para="Oct 20th 2023 | 7PM Onwards"
                  image="img/Home/Rickey1.jpg"
                />
              </a>
            </div>
            <div className="col-md-4">
              <a href="/Javedali">
                <Card6
                  title="Javed Ali Live In Concert"
                  para="Oct 28th 2023 | 6PM Onwards"
                  image="img/Events/javeedali.jpg"
                />
              </a>
            </div><div className="col-md-4">
              <a href="https://in.bookmyshow.com/events/king-new-life-india-tour-by-ti-live-bengaluru/ET00369842?groupEventCode=ET00369864">
                <Card6
                  title="King Live In Concert"
                  para="Oct 28th 2023 | 6PM Onwards"
                  image="img/Events/Kinglive.jpg"
                />
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default EventCard;
