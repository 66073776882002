import React, { Fragment, useEffect, useState } from "react";
import "./Form1.css";
import Container from "react-bootstrap/esm/Container";
import { Link } from "react-router-dom";

// const Form1 = ({ id, title, para }) => {


function Form1() {
  const [formData, setFormData] = useState({
    name: '',
    phoneNo: '',
    email: '',
    message: '',
  });

  const [thankYouMessage, setThankYouMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if any of the fields are empty
    if (!formData.name || !formData.phoneNo || !formData.email || !formData.message) {
      setErrorMessage('All fields are required');

      // Clear the error message after 2 seconds
      setTimeout(() => {
        setErrorMessage('');
      }, 10000);

      return;
    }

    try {
      const response = await fetch('https://admin.phoenixmallofasia.com/api/insert', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Data inserted successfully
        setThankYouMessage('Thank you');
        setErrorMessage('');

        // Clear the form data after 10 seconds
        setTimeout(() => {
          setThankYouMessage('');
          setFormData({
            name: '',
            phoneNo: '',
            email: '',
            message: '',
          });
        }, 10000);

      } else {
        // Failed to insert data
        setErrorMessage('Failed to insert data');
        setThankYouMessage('');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred');
      setThankYouMessage('');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
<div className="form1 py-xl-5 pt-4 pb-3" id="event-form">
      <div className="main-pad mx-auto ">
        <Fragment>
          <div className="row pt-md-3">
            <div className="col-md-7">
              <div className="mt-md-5">

                <h2> Have a question? Connect with us!</h2>
                <p>Write to us and we will reach out shortly.</p>

              </div>
            </div>
            <div className="col-md-5">
              <form onSubmit={handleSubmit}>
                {thankYouMessage && <p>{thankYouMessage}</p>}
                {errorMessage && <p>{errorMessage}</p>}
                <div className="row mt-md-0 mt-3">
                  <div className="col-md-6 pe-md-1">
                    <input type="text" name="name" placeholder="Name" onChange={handleChange} value={formData.name} />
                  </div>
                  <div className="col-md-6 ps-md-1">
                    <input type="number" name="phoneNo" placeholder="Phone No" onChange={handleChange} value={formData.phoneNo} />
                  </div>
                  <div className="col-md-12">
                    <input type="email" name="email" placeholder="Email" onChange={handleChange} value={formData.email} />
                  </div>
                  <div className="col-md-12">
                    <textarea name="message" rows="3" placeholder="Message" className="w-100" onChange={handleChange} value={formData.message}></textarea>
                  </div>

                  <input type="submit" className="submit_btn" name="SUBMIT" />

                </div>
              </form>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
};

export default Form1;
