import React, { Fragment } from "react";
import "../../pages/Leisure.css";
import MovieCard from "../Common/Card/Card7";

const LeisureInox = (props) => {
  return (
    <Fragment>
      <div className="leisure_inox my-md-5 mt-4">
        <div className="main-pad">
          <div className="d-flex py-md-3">
            <h2> Coming Soon </h2>
            {/* <hr /> */}
          </div>
          <div className="row w-100 ms-0">
            {/*<div className="col-md-3 px-md-3 px-0">
              <MovieCard playurl="" title="Avengers End Game" para="English | Telugu | Kannada | Tamil | Malyalam" image="img/Leisure/poster1.jpg" />
            </div>
            <div className="col-md-3 px-md-3 px-0">
              <MovieCard playurl="" title="D.I Jeo Snake Eyes" para="English" image="img/Leisure/poster2.jpg" />
            </div>
            <div className="col-md-3 px-md-3 px-0">
              <MovieCard playurl="" title="The Gray Man" para="English | Telugu | Kannada | Tamil | Malyalam" image="img/Leisure/poster3.jpg" />
            </div>
            <div className="col-md-3 px-md-3 px-0">
              <MovieCard playurl="" title="Avatar" para="English | Telugu " image="img/Leisure/poster4.jpg" />
  </div>*/}
          </div>
        </div>

        {/* <Inox />
        <InoxMob />
        <Carousel3
          title="UPCOMING MOVIES"
          subtitle="Coming to theatres on 25th March 2023"
          button="PRE-BOOK"
          image="img/Leisure/upcoming.png"
        /> */}
      </div>
    </Fragment>
  );
};

export default LeisureInox;
