import { useState } from "react";
import "./LuxEvents.css";
import Container from "react-bootstrap/esm/Container";
const content = [
  [
    <div className="row mt-md-5 pt-md-5 pt-xl-0">
      <div className="col-md-6">
        <img src="img/Home/events1.jpg" />
      </div>
      <div className="col-md-6 pt-md-5 ">
        <header>
          <h2> Events</h2>
        </header>
        <div className="text mt-md-5">
          <h3>
            {" "}
            Javed Ali Live in Concert{" "}
          </h3>
          <p>
          Javed Ali is a very well appreciated, prolific Indian Playback Singer. Born and brought up in Delhi. Javed Ali started singing at his very early age with his father Ustad Hamid Hussian.
          </p>
          <p>
            {" "}
            <span>Date: </span>21st Oct.2023 
          </p>
          <p>
            {" "}
            <span>Location: </span> Courtyard, Phoenix Mall Of Asia
          </p>
        </div>
      </div>
    </div>,
  ],
  [
    <div className="row mt-md-5 pt-md-5 pt-xl-0">
      <div className="col-md-6">
        <img src="img/Home/Kinglive.jpg" />
      </div>
      <div className="col-md-6 pt-md-5 ">
        <header>
          <h2> Events</h2>
        </header>
        <div className="text mt-md-5">
          <h3>
            {" "}
            King Live in Concert{" "}
          </h3>
          <p>
          It’s time for the biggest India tour 🇮🇳
We’ve put our blood & sweat to make this tour an experience of a lifetime. Please come and join us to be a part of this moment.
          </p>
          <p>
            {" "}
            <span>Date: </span>28th Oct.2023
          </p>
          <p>
            {" "}
            <span>Location: </span> Courtyard, Phoenix Mall Of Asia
          </p>
        </div>
      </div>
    </div>,
  ],
  [],
  [],
  [],
  [],
  [],
];

export default function LuxEvents() {
  const [activeContentIndex, setActiveContentIndex] = useState(0);

  return (
    <div className="lux_events py-md-0 pt-4">
      <Container className="my-md-4">
        <div id="tabs">
          <menu>
            <button
              className={activeContentIndex === 0 ? "active" : ""}
              onClick={() => setActiveContentIndex(0)}
            >
              <div className="dot"></div> Sat - 21 Oct.
            </button>
            <button
              className={activeContentIndex === 1 ? "active" : ""}
              onClick={() => setActiveContentIndex(1)}
            >
              <div className="dot"></div> Sat - 28 Oct.
            </button>
            <button
              className={activeContentIndex === 2 ? "active" : ""}
              onClick={() => setActiveContentIndex(2)}
            >
              <div className="dot"></div> 
            </button>
            <button
              className={activeContentIndex === 3 ? "active" : ""}
              onClick={() => setActiveContentIndex(3)}
            >
              <div className="dot"></div> 
            </button>
          </menu>
          <div id="tab-content">
            <ul>
              {content[activeContentIndex].map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
}
