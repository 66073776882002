import React, { Fragment } from "react";
import Carousel3 from "../Common/Carousel/Carousel3";
import ParkCard from "../Common/Card/Card8";

const LeisurePark = (props) => {
  return (
    <Fragment>
      <div className="leisure_park pt-md-5 pb-md-4 pt-4" >
        <div className="main-pad">
          <div className="d-flex py-md-2">
            <h2> Coming Soon </h2>
            {/* <hr /> */}
          </div>
          <div className="row mb-md-3">
            {/*<div className="col-md-4">
              <ParkCard
                title="MAC Mastercalss"
                subtitle="By ESSILLE HEISNAM"
                para="Date: 09th DEC 2023 at 4:00 PM - 5:00 PM"
                image="img/Leisure/fp1.jpg"
              />
  </div>*/}
            {/*<div className="col-md-4">
              <ParkCard
                title="BMW 5 Series"
                subtitle="Brand Launch"
                para="Date: 02nd Aug 2023"
                image="img/Leisure/fp2.jpg"
              />
            </div>
            <div className="col-md-4">
              <ParkCard
                title="Vir Das"
                subtitle="Standup Comedy"
                para="Date: 02nd Aug 2023"
                image="img/Leisure/fp3.jpg"
              />
  </div>*/}
          </div>
        </div>
        <Carousel3 image="img/Leisure/upcoming.png" />
      </div>
    </Fragment>
  );
};

export default LeisurePark;
