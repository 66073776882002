import React, { Fragment } from "react";
import "./SearchResult.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";

const SearchResult = (props) => {
  return (
    <div className="search_result">
      <Fragment>
        <Header />
        <Sidebar />
        <div className="search-detail">
          <div className="main-pad w-75 border-right">
            <div className="d-flex pb-md-4 pb-3">
              <div>
                <img src="img/adidas-logo.jpg" />
              </div>
              <div className="text p-md-5 p-4">
                <h3> Adidas </h3>
                <a href="brand-detail"> CONTINUE READING </a>
              </div>
            </div>
            <hr />
            <div className="py-md-4 pb-4">
                <h3> Always step ahead </h3>
                <p> A collection that will leave you speechless. Choose from a large selection of well-known brands. Always Step Ahead Foot Fashion, Bags, Luggage & Accessories Sportswear & Sportsgear Woodland Speedo Puma…</p>
                <a href="/category-detail"> CONTINUE READING </a>
            </div>
            <hr/>
            <div className="py-md-4 pb-4">
                <h3> Fashionista’s Dream </h3>
                <p> A collection that will leave you speechless. Choose from a large selection of well-known brands. Always Step Ahead Foot Fashion, Bags, Luggage & Accessories Sportswear & Sportsgear Woodland Speedo Puma…</p>
                <a href="/category-detail"> CONTINUE READING </a>
            </div>
            <hr/>
            <div className="py-md-4 pb-4">
                <h3> Brand </h3>
                <p> A collection that will leave you speechless. Choose from a large selection of well-known brands. Always Step Ahead Foot Fashion, Bags, Luggage & Accessories Sportswear & Sportsgear Woodland Speedo Puma…</p>
                <a href="/brands"> CONTINUE READING </a>
            </div>
            <hr/>
            <div className="py-md-4">
                <h3> Home1 </h3>
                <p> A collection that will leave you speechless. Choose from a large selection of well-known brands. Always Step Ahead Foot Fashion, Bags, Luggage & Accessories Sportswear & Sportsgear Woodland Speedo Puma…</p>
                <a href="/"> CONTINUE READING </a>
            </div>
            <hr/>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default SearchResult;
