import EventVideo from "../../assets/Home/anup.mp4";
import RickeyVideo from "../../assets/Home/gauravgupta.mp4";
import BannerVideo from "../../assets/Home/DJ Aqeel.mp4";
import Amittandon from "../../assets/Home/Amittandon.mp4";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Events.css";
import Button1 from "../Common/Button/Button1";

function Events() {
  return (
    <div className="events pt-md-4 pb-md-0 pb-4">
      <header className="main-pad pe-0">
        <div className="d-flex">
          <h2> Events &nbsp; </h2>
          <hr />
        </div>
      </header>
      <div className="main-pad mt-md-0">
        <Tabs
          defaultActiveKey="1"
          id="uncontrolled-tab-example"
          className="mb-4"
        >
          <Tab eventKey="1" title="13th july- DJ Suketu">
          <div className="row mt-md-4">
              {" "}
              <div className="col-md-6">
                <img src="img/Home/Anup Jalota.jpeg" />{" "}
              </div>{" "}
              <div className="col-md-6">
                {" "}
                <video className="w-100" loop autoPlay muted>
                  <source src={EventVideo} type="video/mp4" />{" "}
                </video>{" "}
                <div className="text">
                  {" "}
                  <h3>
                  Timeless ghazals by a very reknowed Anup Jalota  {" "}
                  </h3>{" "}
                  <p>
                    <span>Date: </span> 25th May.2024 {" "}
                  </p>{" "}
                  <p>
                    <span>Location: </span> Fan park, phoenix mall of asia {" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </Tab>
          <Tab eventKey="2" title="31st May- DJ Aqeel">
            <div className="row">
              {" "}
              <div className="col-md-6">
                <img src="img/Home/aqeel.jpeg" />{" "}
              </div>{" "}
              <div className="col-md-6">
                {" "}
                <video className="w-100" loop autoPlay muted>
                  <source src={BannerVideo} type="video/mp4" />{" "}
                </video>{" "}
                <div className="text">
                  {" "}
                  <h3>
                  Bollywood Techno DJ Night with DJ Aqeel at Dobaara {" "}
                  </h3>{" "}
                  <p>
                    <span>Date: </span>31st May.2024 {" "}
                  </p>{" "}
                  <p>
                    <span>Location: </span> Dobaara, 3rd Floor, phoenix mall of asia {" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>
  </Tab>
          <Tab eventKey="3" title="1st June- Gaurav Gupta">
            <div className="row mt-md-4">
              {" "}
              <div className="col-md-6">
                <img src="img/Home/Gaurav Gupta.jpg" />{" "}
              </div>{" "}
              <div className="col-md-6">
                {" "}
                <video className="w-100" loop autoPlay muted>
                  <source src={RickeyVideo} type="video/mp4" />{" "}
                </video>{" "}
                <div className="text">
                  {" "}
                  <h3>
                  Ravi Gupta Live Standup comedy Show  {" "}
                  </h3>{" "}
                  <p>
                    <span>Date: </span> 1st June.2024 {" "}
                  </p>{" "}
                  <p>
                    <span>Location: </span> Fan Park, Phoenix Mall Of Asia{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>
  </Tab>
          {/*<Tab eventKey="4" title="25th Feb Amit Tondon">
            <div className="row">
              {" "}
              <div className="col-md-6">
                <img src="img/Home/Amittandon.jpeg" />{" "}
              </div>{" "}
              <div className="col-md-6">
                {" "}
                <video className="w-100" loop autoPlay muted>
                  <source src={BannerVideo} type="video/mp4" />{" "}
                </video>{" "}
                <div className="text">
                  {" "}
                  <h3>
                  stand-up comedy fun! Dive into laughter with Amit Tandon {" "}
                  </h3>{" "}
                  <p>
                    <span>Date: </span>25th Feb 2024 {" "}
                  </p>{" "}
                  <p>
                    <span>Location: </span> Fan Park, Phoenix Mall Of Asia{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>
  </Tab>*/}
      </Tabs>
      </div>
      <div className="view-btn mt-md-4">
        <a href="https://events.phoenixmallofasia.com/">
          <Button1 title="EXPLORE" />
        </a>
      </div>
    </div>
  );
}

export default Events;
