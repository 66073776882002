import React, { Fragment, useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./SignUp.css";
import axios from 'axios';

function SignUp() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    password: '',
    message: '',
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setSuccess(false);
        setError(null);
      }, 5000); // Adjust the time interval as needed (e.g., 5000 milliseconds for 5 seconds)

      return () => clearTimeout(timer);
    }
  }, [success, error]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any of the required fields are empty
    if (
      !formData.firstName ||
      !formData.email ||
      !formData.password ||
      !formData.message
    ) {
      setError('Please fill in all the required fields.');
      return;
    }

    try {
      const response = await axios.post('https://admin.phoenixmallofasia.com/api/signup', formData);
      console.log(response.data);
      setSuccess(true); // Show the "thank you" message
      setError(null); // Clear any previous error
      setFormData({ // Clear the form input values
        firstName: '',
        email: '',
        password: '',
        message: '',
      });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError('Email already exists');
      } else {
        setError('Error submitting the form');
      }
    }
  };

  return (
    <>
      <Button className="sup" variant="primary" onClick={handleShow}>
        SignUp
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className="sign_up">
            <Container className="px-0">
              <Form onSubmit={handleSubmit}>
                <h2>Sign Up / Register Today</h2>

                <Row>
                  {success && <p style={{ color: 'green' }}>Thank you for signing up!</p>}
                  {error && <p style={{ color: 'red' }}> {error}</p>}
                  <Col>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={formData.firstName}
                      onChange={(e) => setFormData({ ...formData, firstName: e.target.value })} required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })} required
                    />
                  </Col>
                  <Col>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Phone Number"
                      value={formData.password}
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })} required
                    />
                  </Col>
                </Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formGridAddress1">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Message"
                      value={formData.message}
                      onChange={(e) => setFormData({ ...formData, message: e.target.value })} required
                    />
                  </Form.Group>
                </Col>
                <Button variant="" type="submit" onClick={handleSubmit}>
                  SUBMIT
                </Button>
              </Form>
            </Container>
          </div>
        </Modal.Header>
      </Modal>
    </>
  );
}

export default SignUp;
