import React, { Fragment } from "react";
import "./Gift.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import AboutCard from "../components/Gift/AboutCard";
import PheonixCard from "../components/Gift/PheonixCard";
import CorporateEnquiry from "../components/Gift/CorporateEnquiry";
import Faq from "../components/Gift/Faq";

const Gift = (props) => {
  return (
    <Fragment>
      <div className="gift">
        <Header />
        <Sidebar />
        <Banner image="img/Gift/banner.jpg" />
        <AboutCard />
        <div className="main-pad pb-md-5">
          <div className="gift-card">
            <div className="row">
              <div className="col-md-4">
                <div className="card shadow-sm">
                  <img src="img/Gift/cb.png" />
                  <div className="text">
                    <h3> Check Balance </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card shadow-sm">
                  <img src="img/Gift/faq.png" />
                  <div className="text">
                    <h3> FAQs </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card shadow-sm">
                  <img src="img/Gift/co.png" />
                  <div className="text">
                    <h3> Corporate / Bulk Orders </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PheonixCard />
        <div className="gift-image">
            <img src="img/Gift/img.jpg" />
        </div>
        <CorporateEnquiry />
        <Faq />
      </div>
    </Fragment>
  );
};

export default Gift;
