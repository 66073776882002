import React, { useState } from "react";
import Card3 from "../Common/Card/Card3";
import Brand1 from "../../assets/Brands/i1.png";
import Brand2 from "../../assets/Brands/i2.png";
import Brand3 from "../../assets/Brands/i3.png";
import Brand4 from "../../assets/Brands/i4.png";
import "./BrandsCategory.css";

function BrandView() {
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <div className="brand_view">
      <div className="row px-md-0 px-2">
        <div className="col-md-3 col-6 px-md-3 px-1">
          <a href="/brand-detail">
            <Card3
              category="FASHION"
              image={Brand1}
              location="The Abcd Zone"
              floor="First Floor"
            />
          </a>
        </div>
        <div className="col-md-3 col-6 px-md-3 px-1">
          <a href="/brand-detail">
            <Card3
              category="FASHION"
              image={Brand2}
              location="The Abcd Zone"
              floor="First Floor"
            />
          </a>
        </div>
        <div className="col-md-3 col-6 px-md-3 px-1">
          <a href="/brand-detail">
            <Card3
              category="FASHION"
              image={Brand3}
              location="The Abcd Zone"
              floor="First Floor"
            />
          </a>
        </div>
        <div className="col-md-3 col-6 px-md-3 px-1">
          <a href="/brand-detail">
            <Card3
              category="FASHION"
              image={Brand4}
              location="The Abcd Zone"
              floor="First Floor"
            />
          </a>
        </div>
      </div>
      {isShowMore && (
        <div className="row px-md-0 px-2">
          <div className="col-md-3 col-6 px-md-3 px-1">
            <a href="/brand-detail">
              <Card3
                category="FASHION"
                image={Brand1}
                location="The Abcd Zone"
                floor="First Floor"
              />
            </a>
          </div>
          <div className="col-md-3 col-6 px-md-3 px-1">
            <a href="/brand-detail">
              <Card3
                category="FASHION"
                image={Brand2}
                location="The Abcd Zone"
                floor="First Floor"
              />
            </a>
          </div>
          <div className="col-md-3 col-6 px-md-3 px-1">
            <a href="/brand-detail">
              <Card3
                category="FASHION"
                image={Brand3}
                location="The Abcd Zone"
                floor="First Floor"
              />
            </a>
          </div>
          <div className="col-md-3 col-6 px-md-3 px-1">
            <a href="/brand-detail">
              <Card3
                category="FASHION"
                image={Brand4}
                location="The Abcd Zone"
                floor="First Floor"
              />
            </a>
          </div>
        </div>
      )}

      <button onClick={toggleReadMoreLess}>
        {isShowMore ? "View Less" : "View More"}
      </button>
    </div>
  );
}

export default BrandView;
