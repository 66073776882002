import Form from "react-bootstrap/Form";

function CategorySelect() {
  return (
    <Form.Select aria-label="Default select example">
      <option>CATEGORY</option>
      <option value="1">Cafes, Restaurants & Desserts</option>
      <option value="2">Food Court</option>
    </Form.Select>
  );
}

export default CategorySelect;
