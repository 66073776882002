import React, { Fragment } from "react";
import "./CategoryDetail.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import LaunchBanner from "../assets/Home/panache1.jpg";
import CategoryCard from "../components/Category/CategoryCard";
import CategoryView from "../components/Category/CategoryView";

const CategoryDetail = (props) => {
  return (
    <Fragment>
      <div className="category_detail">
        <Header />
        <Sidebar />
        <Banner
          title="A collection that will leave you speechless."
          para="Choose from a large selection of well-known brands."
          image={LaunchBanner}
        />
        <div className="py-md-5 pt-4">
          <CategoryCard />
        </div>
        
      </div>
    </Fragment>
  );
};

export default CategoryDetail;
