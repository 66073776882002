import React, { Fragment } from "react";
import "./Button4.css";

const Button4 = ({ id, title }) => {
  const addBanner = () => {
    dispatchEvent({
      item: {
        id: id,
        title: title,
      },
    });
  };
  return (
    <div className="button4">
      <Fragment>
        <button> {title} </button>
      </Fragment>
    </div>
  );
};

export default Button4;
