import React, { Fragment } from "react";
import Carousel from "react-bootstrap/Carousel";
import Grandeur1 from "../../assets/Home/grandeur1.jpg";
import Grandeur2 from "../../assets/Home/grandeur2.jpg";
import Grandeur3 from "../../assets/Home/grandeur3.jpg";
import oasis1 from "../../assets/Home/oasis1.jpg";
import oasis2 from "../../assets/Home/oasis2.jpg";
import oasis3 from "../../assets/Home/oasis3.jpg";
import Foodthopia1 from "../../assets/Home/FT1.jpg";
import Foodthopia2 from "../../assets/Home/FT2.jpg";
import Foodthopia3 from "../../assets/Home/FT3.jpg";
import EDA1 from "../../assets/Home/EDA1.jpg";
import EDA2 from "../../assets/Home/EDA2.jpg";
import EDA3 from "../../assets/Home/EDA3.jpg";
import LUXA1 from "../../assets/Home/LUXA1.jpg";
import LUXA2 from "../../assets/Home/LUXA2.jpg";
import LUXA3 from "../../assets/Home/LUXA3.jpg";
import FP1 from "../../assets/Home/FP1.jpg";
import FP2 from "../../assets/Home/FP2.jpg";
import FP3 from "../../assets/Home/FP3.jpg";
import NA1 from "../../assets/Home/NA1.jpg";
import NA2 from "../../assets/Home/NA2.jpg";
import NA3 from "../../assets/Home/NA3.jpg";
import EA1 from "../../assets/Home/EA1.jpg";
import EA2 from "../../assets/Home/EA2.jpg";
import EA3 from "../../assets/Home/EA3.jpg";
import AR1 from "../../assets/Home/AR1.jpg";
import AR2 from "../../assets/Home/AR2.jpg";
import AR3 from "../../assets/Home/AR3.jpg";
import DX1 from "../../assets/Home/DX1.jpg";
import DX2 from "../../assets/Home/DX2.jpg";
import DX3 from "../../assets/Home/DX3.jpg";
import "./Grandeur.css";

const Grandeur = (props) => {
  return (
    <Fragment>
      <div className="grandeur pt-xl-4 pb-md-4 pt-4 pb-2">
        <div className="main-pad">
          <div className="d-flex">
            <h2 className="py-md-4 pb-3 text-center w-100">
              {" "}
              WORLD OF GRANDEUR
            </h2>
            {/* <hr /> */}
          </div>
          <Carousel>
          <Carousel.Item>
              <div className="row mt-xl-5 mt-md-4 mb-md-3 ms-md-0">
                <div className="col-md-6 px-md-0">
                  <img src={oasis1} className="w-100" />
                </div>
                <div className="col-md-6 mb-md-4 mb-3 px-md-3">
                  <div className="grandeur-text ps-md-5">
                    <div>
                      <h3> THE OASIS </h3>
                      <p className="mt-md-4 mt-2">
                        The Oasis at the heart of the mall pays homage to nature, 
                        particularly the beautiful tropics of Asia as well as Bangalore’s 
                        botanical beauty. This eco-friendly space invites you to pause, 
                        relax and lose yourself in wonder, amidst an enchanting play of light.
                        An ‘open plan’ adds to the fun and seamless journey as you meander 
                        through the spaces. MOA is conceptualised as a new age retail experience 
                        that breaks the boundaries between retailer and customer.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-md-0 mt-md-3 d-none d-sm-block">
                  <img src={oasis2} className="w-100" />
                </div>
                <div className="col-md-6 px-md-3 mt-md-3 d-none d-sm-block">
                  <img src={oasis3} className="w-100" />
                </div>
              </div>
            </Carousel.Item>
          <Carousel.Item>
              <div className="row mt-xl-5 mt-md-4 mb-md-3 ms-md-0">
                
                <div className="col-md-6 px-md-0">
                  <img src={Grandeur1} className="w-100" />
                </div>
                <div className="col-md-6 mb-md-4 mb-3 px-md-3">
                  <div className="grandeur-text ps-md-5">
                    <div>
                      <h3> LUXURY BOULEVARD </h3>
                      <p className="mt-md-4 mt-2">
                        Phoenix Mall Of Asia makes a statement at every turn, every
                        corner. You’ll discover a world of premium vintage
                        opulence at Luxury Boulevard, featuring high end
                        designer stores in a walk-through reminiscent of the
                        world’s best streets for luxury shopping. Visitors get
                        to immerse themselves in an iconic retail environment
                        featuring creative use of elements of nature and
                        technology – embracing refinement and the spirit of the
                        times.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-md-0 mt-md-3 d-none d-sm-block">
                  <img src={Grandeur2} className="w-100" />
                </div>
                <div className="col-md-6 px-md-3 mt-md-3  d-none d-sm-block">
                  <img src={Grandeur3} className="w-100" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row mt-xl-5 mt-md-4 mb-md-3 ms-md-0">
                <div className="col-md-6 px-md-0">
                  <img src={Foodthopia1} className="w-100" />
                </div>
                <div className="col-md-6 mb-md-4 mb-3 px-md-3">
                  <div className="grandeur-text ps-md-5">
                    <div>
                      <h3> FOODTHOPIA </h3>
                      <p className="mt-md-4 mt-2">
                      More is more at MOA. Enter food court Foodthopia
                       –  enriching your dining experience with open, 
                       chic and contemporary design. Foodthopia features 
                       a delectable range of local and international cuisines, 
                       delivered with a twist! You’ll see for yourself that there’s 
                       a narrative accompanying every culinary experience. This 
                       tiered atrium has a strong communal vibe yet comes with 
                       semi-private zones that can be reserved for celebrations 
                       and special events. Don’t miss the fun!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-md-0 mt-md-3 d-none d-sm-block">
                  <img src={Foodthopia2} className="w-100" />
                </div>
                <div className="col-md-6 px-md-3 mt-md-3 d-none d-sm-block">
                  <img src={Foodthopia3} className="w-100" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row mt-xl-5 mt-md-4 mb-md-3 ms-md-0">
                <div className="col-md-6 px-md-0">
                  <img src={EDA1} className="w-100" />
                </div>
                <div className="col-md-6 mb-md-4 mb-3 px-md-3">
                  <div className="grandeur-text ps-md-5">
                    <div>
                      <h3> EDEN ARCADIA </h3>
                      <p className="mt-md-4 mt-2">
                      Experience enchantment on a whole other level at Eden Arcadia, 
                      one of the quieter zones to pause for a coffee or take a 
                      few moments for yourself. The feature wall on the ground floor 
                      entrance lobby spans 5 storeys, meant to be a mystical ‘pathway’ 
                      through a digital rainforest. The high-definition backdrop and 
                      touch of nature makes a great setting for the retail 
                      therapy you came for.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-md-0 mt-md-3 d-none d-sm-block">
                  <img src={EDA2} className="w-100" />
                </div>
                <div className="col-md-6 px-md-3 mt-md-3 d-none d-sm-block">
                  <img src={EDA3} className="w-100" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row mt-xl-5 mt-md-4 mb-md-3 ms-md-0">
                <div className="col-md-6 px-md-0">
                  <img src={LUXA1} className="w-100" />
                </div>
                <div className="col-md-6 mb-md-4 mb-3 px-md-3">
                  <div className="grandeur-text ps-md-5">
                    <div>
                      <h3> LUXE ATRIA </h3>
                      <p className="mt-md-4 mt-2">
                      Phoenix Mall Of Asia has several modern and stylish elements that 
                      accentuate its boldness and please the eye. One such is the 
                      Luxe Atria with a gorgeous and highly Instagrammable backdrop. 
                      An imposing high-wall feature has been designed to display 
                      constant movement. Look for a stunning digital waterfall 
                      that enhances the natural vibe.  
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-md-0 mt-md-3 d-none d-sm-block">
                  <img src={LUXA2} className="w-100" />
                </div>
                <div className="col-md-6 px-md-3 mt-md-3 d-none d-sm-block">
                  <img src={LUXA3} className="w-100" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row mt-xl-5 mt-md-4 mb-md-3 ms-md-0">
                <div className="col-md-6 px-md-0">
                  <img src={FP1} className="w-100" />
                </div>
                <div className="col-md-6 mb-md-4 mb-3 px-md-3">
                  <div className="grandeur-text ps-md-5">
                    <div>
                      <h3> FAN PARK </h3>
                      <p className="mt-md-4 mt-2">
                      A great place to meet friends, catch that big game, 
                      get work done, enjoy your coffee, scrumptious bites and more. 
                      Fan Park offers flexible floor space for events ranging from 
                      music performances to fashion shows and product launches. 
                      With restaurant and café Lifethopia right alongside, 
                      it has a pretty amazing vibe. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-md-0 mt-md-3 d-none d-sm-block">
                  <img src={FP2} className="w-100" />
                </div>
                <div className="col-md-6 px-md-3 mt-md-3 d-none d-sm-block">
                  <img src={FP3} className="w-100" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row mt-xl-5 mt-md-4 mb-md-3 ms-md-0">
                <div className="col-md-6 px-md-0">
                  <img src={NA1} className="w-100" />
                </div>
                <div className="col-md-6 mb-md-4 mb-3 px-md-3">
                  <div className="grandeur-text ps-md-5">
                    <div>
                      <h3> NATURE ABSTRACT </h3>
                      <p className="mt-md-4 mt-2">
                      After a captivating entrance, you’ll notice each floor 
                      at the mall is thematically designed, continuing to 
                      inspire awe and offer a dynamic experience. 
                      The welcoming ‘nature’ of the ground floor is undeniable. Visitors can explore this artistic oasis, appreciating the harmony between contemporary design and the natural world. It's a place where art and nature converge, offering a refreshing and contemplative shopping experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-md-0 mt-md-3 d-none d-sm-block">
                  <img src={NA2} className="w-100" />
                </div>
                <div className="col-md-6 px-md-3 mt-md-3 d-none d-sm-block">
                  <img src={NA3} className="w-100" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row mt-xl-5 mt-md-4 mb-md-3 ms-md-0">
                <div className="col-md-6 px-md-0">
                  <img src={EA1} className="w-100" />
                </div>
                <div className="col-md-6 mb-md-4 mb-3 px-md-3">
                  <div className="grandeur-text ps-md-5">
                    <div>
                      <h3> ECO EARTH </h3>
                      <p className="mt-md-4 mt-2">
                      Earth-friendly and modern, the first 
                      floor is wonderfully expressive, too, 
                      elevating the fun of shopping and hanging out. Shoppers can explore eco-friendly brands, from organic fashion to upcycled home decor. Educational kiosks offer insights into sustainability, empowering visitors to make eco-conscious choices. It's a vibrant space where shoppers can align their values with their purchases, fostering a more sustainable and eco-aware lifestyle. The Eco Earth Floor Zone is a tribute to our planet's well-being, reminding us that every purchase can make a positive impact.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-md-0 mt-md-3 d-none d-sm-block">
                  <img src={EA2} className="w-100" />
                </div>
                <div className="col-md-6 px-md-3 mt-md-3 d-none d-sm-block">
                  <img src={EA3} className="w-100" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row mt-xl-5 mt-md-4 mb-md-3 ms-md-0">
                <div className="col-md-6 px-md-0">
                  <img src={AR1} className="w-100" />
                </div>
                <div className="col-md-6 mb-md-4 mb-3 px-md-3">
                  <div className="grandeur-text ps-md-5">
                    <div>
                      <h3> AQUATIC REFLECTION </h3>
                      <p className="mt-md-4 mt-2">
                      Name says it all. It’s a different kind of luxurious 
                      feel up here on the second floor. Explore 
                      sophisticated and dramatic details 
                      that transport you to a different world. The ambiance is enhanced by soothing sounds of the sea, creating a peaceful respite for weary shoppers. This unique zone invites guests to pause, reflect, and connect with nature, offering a delightful escape from the urban rush. It's a tranquil haven where retail therapy meets the serenity of the deep blue.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-md-0 mt-md-3 d-none d-sm-block">
                  <img src={AR2} className="w-100" />
                </div>
                <div className="col-md-6 px-md-3 mt-md-3 d-none d-sm-block">
                  <img src={AR3} className="w-100" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row mt-xl-5 mt-md-4 mb-md-3 ms-md-0">
                <div className="col-md-6 px-md-0">
                  <img src={DX1} className="w-100" />
                </div>
                <div className="col-md-6 mb-md-4 mb-3 px-md-3">
                  <div className="grandeur-text ps-md-5">
                    <div>
                      <h3> DIGITAL X </h3>
                      <p className="mt-md-4 mt-2">
                      Adding a new dimension to the experience of MOA. 
                      It’s bold and futuristic. Shoppers are transported to a futuristic realm, featuring augmented reality mirrors, interactive product displays, and immersive virtual reality experiences. It's a playground for tech enthusiasts, blending shopping with entertainment seamlessly. Visitors can try on virtual outfits, experience immersive gaming, and explore the latest gadgets. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-md-0 mt-md-3 d-none d-sm-block">
                  <img src={DX2} className="w-100" />
                </div>
                <div className="col-md-6 px-md-3 mt-md-3 d-none d-sm-block">
                  <img src={DX3} className="w-100" />
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </Fragment>
  );
};

export default Grandeur;
