import React, { useState } from "react";
import Card4 from "../Common/Card/Card4";
import Dine1 from "../../assets/Dine/dine1.png";
import Dine2 from "../../assets/Dine/dine2.png";
import Dine3 from "../../assets/Dine/dine3.png";
import "./DineCategory.css";

function DineView() {
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <div className="dine_view">
      <div className="row">
        <div className="col-md-4">
          <a href="/dine-detail">
            <Card4
              category="CAFE"
              title="CAFE TESU"
              image={Dine1}
              location="The Abcd Zone"
              floor="First Floor"
            />
          </a>
        </div>
        <div className="col-md-4">
          <a href="/dine-detail">
            <Card4
              category="RESTAURANT"
              title="BUKHARA"
              image={Dine2}
              location="The Abcd Zone"
              floor="First Floor"
            />
          </a>
        </div>
        <div className="col-md-4">
          <a href="/dine-detail">
            <Card4
              category="FINE-DINE"
              title="ANARDANA"
              image={Dine3}
              location="The Abcd Zone"
              floor="First Floor"
            />
          </a>
        </div>
      </div>
      {isShowMore && (
        <div className="row">
          <div className="col-md-4">
            <a href="/dine-detail">
              <Card4
                category="CAFE"
                title="CAFE TESU"
                image={Dine1}
                location="The Abcd Zone"
                floor="First Floor"
              />
            </a>
          </div>
          <div className="col-md-4">
            <a href="/dine-detail">
              <Card4
                category="RESTAURANT"
                title="BUKHARA"
                image={Dine2}
                location="The Abcd Zone"
                floor="First Floor"
              />
            </a>
          </div>
          <div className="col-md-4">
            <a href="/dine-detail">
              <Card4
                category="FINE-DINE"
                title="ANARDANA"
                image={Dine3}
                location="The Abcd Zone"
                floor="First Floor"
              />
            </a>
          </div>
        </div>
      )}

      <button onClick={toggleReadMoreLess}>
        {isShowMore ? "View Less" : "View More"}
      </button>
    </div>
  );
}

export default DineView;
