import React, { Fragment } from "react";
import "./Collection.css";
import Carousel from "react-bootstrap/Carousel";

const Collection = ({ props }) => {
  return (
    <div className="collection py-md-5 py-4">
      <Fragment>
        <div className="main-pad pe-0">
          <div className="d-flex mt-md-3">
            <h2> NEW COLLECTIONS </h2>
            <hr />
          </div>
        </div>
        <Carousel className="mt-md-5 mt-4">
          <Carousel.Item>
            <div className="row">
              <div className="col-md-6 px-1">
                <div className="sect1">
                <a href="https://www.phoenixmallofasia.com/brand/Malabar">
                  <img src="https://cms.phoenixmallofasia.com/wp-content/uploads/2024/05/newcollection1.jpg" />
                  </a>
                  <h3> Explore now!! </h3>
                </div>
                <div className="sect2 mt-md-1">
                <a href="https://www.phoenixmallofasia.com/brand/Malabar">
                  <img src="https://cms.phoenixmallofasia.com/wp-content/uploads/2024/05/newcollection3.jpg" />
                  </a>
                  <h3> Check it out!! </h3>
                </div>
              </div>
              <div className="col-md-6 px-0">
                <div className="sect3">
                <a href="https://www.phoenixmallofasia.com/brand/Malabar">
                  <img src="https://cms.phoenixmallofasia.com/wp-content/uploads/2024/05/newcollection2.jpg" />
                  </a>
                  <h3> Explore!! </h3>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row">
              <div className="col-md-6 px-1">
                <div className="sect1">
                <a href="https://www.phoenixmallofasia.com/brand/Mango">
                  <img src="https://cms.phoenixmallofasia.com/wp-content/uploads/2024/05/nc2.png" />
                  </a>
                  <h3> Explore now!! </h3>
                </div>
                <div className="sect2 mt-md-1">
                <a href="https://www.phoenixmallofasia.com/brand/Adidas">
                  <img src="https://cms.phoenixmallofasia.com/wp-content/uploads/2024/05/rykfdgf.jpg" />
                  </a>
                  <h3> Check it out!! </h3>
                </div>
              </div>
              <div className="col-md-6 px-0">
                <div className="sect3">
                <a href="https://www.phoenixmallofasia.com/brand/Parcos%20Luxe">
                  <img src="https://cms.phoenixmallofasia.com/wp-content/uploads/2024/05/ytdcfhyj.jpg" />
                  </a>
                  <h3> Explore!! </h3>
                </div>
              </div>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item>
            <img src={image3} />
            <h3> {title3}</h3>
          </Carousel.Item>
          <Carousel.Item>
            <img src={image4} />
            <h3> {title4}</h3>
          </Carousel.Item>
          <Carousel.Item>
            <img src={image5} />
            <h3> {title5}</h3>
          </Carousel.Item> */}
        </Carousel>
      </Fragment>
    </div>
  );
};

export default Collection;
