import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../pages/About.css";
import Card12 from "../Common/Card/Card12";

export class AboutCard extends Component {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  render() {
    return (
      <div className="offers pb-md-0 pt-md-0 pb-4">
        <div className="main-pad">
          <div class="container-fluid pt-md-4">
            <OwlCarousel
              items={2}
              className="owl-theme mt-md-5 pt-md-3 mt-3"
              loop
              nav
              autoplay
              margin={25}
              responsive={this.state.responsive}
            >
              <div>
                <Card12
                  image={"img/About/1.jpg"}
                  title=" THE OASIS "
                  para="The Oasis at the heart of the mall pays homage to nature, 
                  particularly the beautiful tropics of Asia as well as Bangalore’s 
                  botanical beauty. This eco-friendly space invites you to pause, 
                  relax and lose yourself in wonder, amidst an enchanting play of light.
                  An ‘open plan’ adds to the fun and seamless journey as you meander 
                  through the spaces. MOA is conceptualised as a new age retail experience 
                  that breaks the boundaries between retailer and customer."
                />
              </div>
              <div>
                <Card12
                  image={"img/About/2.jpg"}
                  title=" NATURE ABSTRACT "
                  para="After a captivating entrance, you’ll notice each floor 
                  at the mall is thematically designed, continuing to 
                  inspire awe and offer a dynamic experience. 
                  The welcoming ‘nature’ of the ground floor is undeniable. Visitors can explore this artistic oasis, appreciating the harmony between contemporary design and the natural world. It's a place where art and nature converge, offering a refreshing and contemplative shopping experience."
                />
              </div>
              <div>
                <Card12
                  image={"img/About/3.jpg"}
                  title="LUXURY BOULVARD"
                  para="PHOENIX Mall Of Asia makes a statement at every turn, every
                  corner. You’ll discover a world of premium vintage
                  opulence at Luxury Boulevard, featuring high end
                  designer stores in a walk-through reminiscent of the
                  world’s best streets for luxury shopping. Visitors get
                  to immerse themselves in an iconic retail environment
                  featuring creative use of elements of nature and
                  technology – embracing refinement and the spirit of the
                  times."
                />
              </div>
              <div>
                <Card12
                  image={"img/About/4.jpg"}
                  title="LUXE ATRIA"
                  para="PHOENIX Mall Of Asia has several modern and stylish elements that 
                  accentuate its boldness and please the eye. One such is the 
                  Luxe Atria with a gorgeous and highly Instagrammable backdrop. 
                  An imposing high-wall feature has been designed to display 
                  constant movement. Look for a stunning digital waterfall 
                  that enhances the natural vibe.  "
                />
              </div>
              <div>
                <Card12
                  image={"img/About/5.jpg"}
                  title="FAN PARK"
                  para="A great place to meet friends, catch that big game, 
                  get work done, enjoy your coffee, scrumptious bites and more. 
                  Fan Park offers flexible floor space for events ranging from 
                  music performances to fashion shows and product launches. 
                  With restaurant and café Lifethopia right alongside, 
                  it has a pretty amazing vibe. "
                />
              </div>
              <div>
                <Card12
                  image={"img/About/6.jpg"}
                  title="FOODTHOPIA"
                  para="More is more at MOA. Enter food court Foodthopia
                  –  enriching your dining experience with open, 
                  chic and contemporary design. Foodthopia features 
                  a delectable range of local and international cuisines, 
                  delivered with a twist! You’ll see for yourself that there’s 
                  a narrative accompanying every culinary experience. This 
                  tiered atrium has a strong communal vibe yet comes with 
                  semi-private zones that can be reserved for celebrations 
                  and special events. Don’t miss the fun!"
                />
              </div>
              <div>
                <Card12
                  image={"img/About/7.jpg"}
                  title="EDEN ARCADIA"
                  para="Experience enchantment on a whole other level at Eden Arcadia, 
                  one of the quieter zones to pause for a coffee or take a 
                  few moments for yourself. The feature wall on the ground floor 
                  entrance lobby spans 5 storeys, meant to be a mystical ‘pathway’ 
                  through a digital rainforest. The high-definition backdrop and 
                  touch of nature makes a great setting for the retail 
                  therapy you came for."
                />
              </div>
              <div>
                <Card12
                  image={"img/About/8.jpg"}
                  title="ECO EARTH"
                  para="Earth-friendly and modern, the first 
                  floor is wonderfully expressive, too, 
                  elevating the fun of shopping and hanging out. Shoppers can explore eco-friendly brands, from organic fashion to upcycled home decor. Educational kiosks offer insights into sustainability, empowering visitors to make eco-conscious choices. It's a vibrant space where shoppers can align their values with their purchases, fostering a more sustainable and eco-aware lifestyle. The Eco Earth Floor Zone is a tribute to our planet's well-being, reminding us that every purchase can make a positive impact."
                />
              </div>
              <div>
                <Card12
                  image={"img/About/9.jpg"}
                  title="DIGITAL X"
                  para="Adding a new dimension to the experience of MOA. 
                  It’s bold and futuristic. Shoppers are transported to a futuristic realm, featuring augmented reality mirrors, interactive product displays, and immersive virtual reality experiences. It's a playground for tech enthusiasts, blending shopping with entertainment seamlessly. Visitors can try on virtual outfits, experience immersive gaming, and explore the latest gadgets. "
                />
              </div>
              <div>
                <Card12
                  image={"img/About/10.jpg"}
                  title="AQUATIC REFLECTION"
                  para="Name says it all. It’s a different kind of luxurious 
                  feel up here on the second floor. Explore 
                  sophisticated and dramatic details 
                  that transport you to a different world. The ambiance is enhanced by soothing sounds of the sea, creating a peaceful respite for weary shoppers. This unique zone invites guests to pause, reflect, and connect with nature, offering a delightful escape from the urban rush. It's a tranquil haven where retail therapy meets the serenity of the deep blue."
                />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutCard;
