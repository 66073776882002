import React, { Fragment } from "react";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import "./Faq.css";

const Faq = (props) => {
  return (
    <Fragment>
      <div className="faq pb-md-5 py-0">
        <div className="main-pad pe-0">
          <div className="d-flex">
            <h2>FREQUENTLY ASKED QUESTION</h2>
            <hr />
          </div>
        </div>
        <div className="main-pad pb-4">
          <Container className="mt-md-4">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>What is Phoenix Card?</Accordion.Header>
                <Accordion.Body>
                The Phoenix Gift Card is a prepaid card which cannot be reloaded. It’s a onetime load card. It is a financial instrument which can be preloaded with a designated amount in Indian Rupees for subsequent use as per cardholder’s requirement..
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Where Can I Use The Gift Card?
                </Accordion.Header>
                <Accordion.Body>
                Card can be used at all merchant outlet that accepts VISA cards in Phoenix Citadel Indore, Phoenix Mall Of Asia (Mumbai, Chennai, Pune and Bangalore) Phoenix Palassio Lucknow, Phoenix United (Lucknow & Bareilly), Phoenix Palladium Mumbai only. The card cannot be used to withdraw cash.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                 How Does The Gift Card Work?
                </Accordion.Header>
                <Accordion.Body>
                Every time the gift card is used, the purchase amount is deducted automatically from the card balance. When you swipe the card at any POS machine, the machine will prompt for PIN. Enter your Gift card PIN details to complete the transaction.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How to Use the Gift Card at POS?
                </Accordion.Header>
                <Accordion.Body>
                Swipe the card & input the purchase amount, the terminal will prompt for a PIN. User to punch in the correct PIN. Once the Pin is verified the transaction is authenticated / approved
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                 What Is The Validity Of The Gift Card?
                </Accordion.Header>
                <Accordion.Body>
                The validity of the card is three years from the date of activation.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default Faq;
