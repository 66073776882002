import React, { Fragment } from "react";
import "./CategoryCard.css";
import Card3 from "../Common/Card/Card3";
import Brand1 from "../../assets/Brands/i1.png";
import Brand2 from "../../assets/Brands/i2.png";
import Brand3 from "../../assets/Brands/i3.png";
import Brand4 from "../../assets/Brands/i4.png";
import CategorySelect from "./CategorySelect";
import CategorySearch from "./CategorySearch";
import CategoryView from "./CategoryView";
import { Tab, Tabs } from "react-bootstrap";

const CategoryCard = ({ props }) => {
  return (
    <div className="category_card pt-md-4 pt-0">
      <Fragment>
        <div className="main-pad d-flex pe-0 ">
          <h2> Always Step Ahead </h2>
          <hr />
        </div>
        <div className="category-filter my-md-5 my-4">
          <div>
            <CategorySelect />
          </div>
          <div className="mt-md-0 mt-3">
            <CategorySearch />
          </div>
        </div>
        <div className="main-pad az-tab">
          <Tabs
            defaultActiveKey="ae"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="ae" title="A-E">
              <div className="main-pad ">
                <CategoryView />
              </div>
            </Tab>
            <Tab eventKey="fj" title="F-J">
              <div className="main-pad ">
                <CategoryView />
              </div>
            </Tab>
            <Tab eventKey="ko" title="K-O">
              <div className="main-pad ">
                <CategoryView />
              </div>
            </Tab>
            <Tab eventKey="pt" title="P-T">
              <div className="main-pad ">
                <CategoryView />
              </div>
            </Tab>
            <Tab eventKey="uz" title="U-Z">
              <div className="main-pad ">
                <CategoryView />
              </div>
            </Tab>
          </Tabs>
        </div>
      </Fragment>
    </div>
  );
};

export default CategoryCard;
