import React from "react";
import "./ContactForm.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button2 from "../Common/Button/Button2";
import { Container, InputGroup } from "react-bootstrap";

const ContactForm = () => {
  return (
    <div className="contact_form pb-md-5 pb-4">
      <div className="d-flex main-pad">
        <h2>GET IN TOUCH WITH US </h2>
        <hr />
      </div>
      <Container>
        <Form className="form mt-md-5 mt-4">
          <Row>
            <Col>
              <Form.Control placeholder="First Name" />
            </Col>
            <Col>
              <Form.Control placeholder="Phone Number " />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control placeholder="Email Address" />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup>
                <Form.Control rows="4" as="textarea" placeholder="Your Message" aria-label="With textarea" />
              </InputGroup>
            </Col>
          </Row>
          <Button2 title="SUBMIT" />
        </Form>
      </Container>
    </div>
  );
};

export default ContactForm;
