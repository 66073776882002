import React from "react";
import { Cont } from "./elements";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

import "./Inox.css";
import Button2 from "../Common/Button/Button2";

class InoxMob extends React.Component {
  render() {
    return (
      <div className="inox d-block d-sm-none">
        <div className="container w-100">
          <div className="d-flex main-pad px-0">
            <h2> INOX MOVIES</h2>
            <hr />
          </div>
          <div className="row align-items-center w-100ms-0 justify-content-center my-3">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="img/inox1.jpg"
                  alt="First slide"
                />
                <div className="box">
                  <div>
                    <a href="/leisure">
                      <Button2 title="BOOK NOW" />
                    </a>
                  </div>
                  <div>
                    <a href="/leisure">
                      <Button2 title="PLAY TRAILER" />
                    </a>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="img/inox2.jpg"
                  alt="Second slide"
                />
                <div className="box">
                  <div>
                    <a href="/leisure">
                      <Button2 title="BOOK NOW" />
                    </a>
                  </div>
                  <div>
                    <a href="/leisure">
                      <Button2 title="PLAY TRAILER" />
                    </a>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="img/inox3.jpg"
                  alt="Third slide"
                />
                <div className="box">
                  <div>
                    <a href="/leisure">
                      <Button2 title="BOOK NOW" />
                    </a>
                  </div>
                  <div>
                    <a href="/leisure">
                      <Button2 title="PLAY TRAILER" />
                    </a>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="img/inox4.jpg"
                  alt="Third slide"
                />
                <div className="box">
                  <div>
                    <a href="/leisure">
                      <Button2 title="BOOK NOW" />
                    </a>
                  </div>
                  <div>
                    <a href="/leisure">
                      <Button2 title="PLAY TRAILER" />
                    </a>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="img/inox5.jpg"
                  alt="Third slide"
                />
                <div className="box">
                  <div>
                    <a href="/leisure">
                      <Button2 title="BOOK NOW" />
                    </a>
                  </div>
                  <div>
                    <a href="/leisure">
                      <Button2 title="PLAY TRAILER" />
                    </a>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="check-btn">
            <a href="/leisure">
              {" "}
              <button type="button"> CHECK OUT ALL SHOWS </button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default InoxMob;
