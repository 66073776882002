import React, { Fragment } from "react";
import "./EventInfo.css";
import Container from "react-bootstrap/esm/Container";
import Button2 from "../Common/Button/Button2";

const EventInfo = (props) => {
  return (
    <div className="event_info py-md-5 pt-3 mt-md-4">
      <Fragment>
        <Container>
          <div className="top d-flex w-100">
            <div>
              <h3> Marvel at the elegant designs from the most premium jewellery brands and get the most dazzling offers. </h3>
              <p> IND VS AUS Live Screening  </p>
            </div>
            <div className="ms-auto">
              <a href="#event-form">
                <Button2 title="Reserve Now" />
              </a>
            </div>
          </div>
          <div className="card shadow">
            <div className="row">
              <div className="col-md-4 col-4 text-center">
                <img src="img/Events/date.png" />
                <p className="mt-md-4 mt-3"> Worldcup finals 2023 </p>
              </div>
              <div className="col-md-4 col-4 text-center">
                <img src="img/Events/time.png" />
                <p className="mt-md-4 mt-3"> 2:00PM</p>
              </div>
              <div className="col-md-4 col-4 text-center">
                <img src="img/Events/location.png" />
                <p className="mt-md-4 mt-3"> Fanpark, Phoenix Mall Of asia </p>
              </div>
            </div>
          </div>
          <p>
          Ricky Kej, a composer from Bengaluru, got his third Grammy Award. Bengaluru based music composer Ricky Kej has won his third Grammy Award for the album "Divine Tides". Stewart Copeland, drummer of the legendary British rock band The Police, who collaborated with Kej on the record, shared the honour with Ricky.
          </p>
          <div className="row my-md-5">
            <div className="col-md-4 px-1 mb-1">
              <img src="img/Events/r2.jpg" />
            </div>
            <div className="col-md-4 px-1 mb-1">
              <img src="img/Events/r3.jpg" />
            </div>
            <div className="col-md-4 px-1">
              <img src="img/Events/r5.jpg" />
            </div>
          </div>
          <div className="tc py-3">
            <h3> Terms and Conditions</h3>
            <ul className="mt-md-4 mt-3">
              <li>
                {" "}
              This Show is a Unpaid show. So, Please reserve your seats by filling the form.
              </li>
              <li>
              Seat Reservation is Subjective. Seat Confirmation is Not guaranteed. 
              </li>
              <li>
              We recommend that you arrive at-least 30 minutes prior at the venue for a seamless entry 
              </li>
            </ul>
          </div>
        </Container>
      </Fragment>
    </div>
  );
};

export default EventInfo;
