import React, { Fragment } from "react";
import "./tc.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import TermsTab from "../components/Terms/TermsTab";

const TermsConditions = (props) => {
  return (
    <Fragment>
      <div className="terms_condition">
        <Header />
        <Sidebar />
        <Banner image="img/terms-banner.png" title="." />
        <TermsTab />
      </div>
    </Fragment>
  );
};

export default TermsConditions;
