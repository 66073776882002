import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./MallServices.css";
import Card10 from "../Common/Card/Card10";

function MallServices() {
  return (
    <div className="mall_services mt-md-4 mt-4">
      <div className="main-pad d-flex pe-0">
        <h2> MALL SERVICES </h2>
        <hr />
      </div>
      <Container className="py-md-5 py-4 w-75">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Parking and Transport</Nav.Link>
                </Nav.Item>
                <hr />
                <Nav.Item>
                  <Nav.Link eventKey="second">Services for Specially Abled</Nav.Link>
                </Nav.Item>
                <hr />
                <Nav.Item>
                  <Nav.Link eventKey="third">Family Services</Nav.Link>
                </Nav.Item>
                <hr />
                <Nav.Item>
                  <Nav.Link eventKey="fourth">Emergency Support</Nav.Link>
                </Nav.Item>
                <hr />
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="row py-md-0 pt-4">
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Valet Parking"
                        image="img/Services/valet.png"
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Cab Pickup Area"
                        image="img/Services/cab.png"
                      />
                    </div>
                    {/*<div className="col-md-3 col-6">
                      <Card10
                        title="Taxi service"
                        image="img/Services/taxi.png"
                      />
                    </div>*/}
                    <div className="col-md-3 col-6">
                      <Card10
                        title="VIP Drop off/Pickup"
                        image="img/Services/vip.png"
                      />
                    </div>
                    {/*<div className="col-md-3 col-6">
                      <Card10
                        title="Charging Stations for Electronic Cars"
                        image="img/Services/charge.png"
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Reserved parking"
                        image="img/Services/reserved.png"
                      />
                    </div>*/}
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Parking for Expecting Mothers"
                        image="img/Services/parking.png"
                      />
                    </div>
                    {/*<div className="col-md-3 col-6">
                      <Card10 title="Car Wash" image="img/Services/wash.png" />
                    </div>
                    <div className="col-md-3 col-6">
                      <Card10
                        title="VIP Valet parking lounge"
                        image="img/Services/lounge.png"
                      />
                    </div>*/}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="row py-md-0 pt-4">
                     <div className="col-md-3 col-6">
                      <Card10
                        title="Parking For Specially Abled"
                        image="img/Services/parking-d.png"
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Rest Room For Specially Abled"
                        image="img/Services/restroom-d.png"
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Wheel Chair"
                        image="img/Services/wheelchair.png"
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <div className="row py-md-0 pt-4">
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Baby Care Facility"
                        image="img/Services/baby-change.png"
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Cinema"
                        image="img/Services/cinema.png"
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Spa & Saloon"
                        image="img/Services/spa.png"
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Fine Dine"
                        image="img/Services/fine dine.png"
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Birthday Cakes & Bouquets"
                        image="img/Services/Birthday cakes.png"
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Bowling"
                        image="img/Services/bowling.png"
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <div className="row py-md-0 pt-4">
                    <div className="col-md-3 col-6">
                      <Card10
                        title="Ambulance"
                        image="img/Services/ambulance.png"
                      />
                    </div>
                    <div className="col-md-3 col-6">
                      <Card10
                        title="First-Aid"
                        image="img/Services/first-aid.png"
                      />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
}

export default MallServices;
