import React, { Fragment } from "react";
import "./EventInfo.css";
import Container from "react-bootstrap/esm/Container";
import Button2 from "../Common/Button/Button2";

const EventInfo = (props) => {
  return (
    <div className="event_info py-md-5 pt-3 mt-md-4">
      <Fragment>
        <Container>
          <div className="top d-flex w-100">
            <div>
              <h3> Javed Ali Live In Concert - Phoenix Mall Of Asia </h3>
              <p> Music Shows | Hindi </p>
            </div>
            <div className="ms-auto">
              {/* <a href="https://in.bookmyshow.com/events/javed-ali-live-in-concert/ET00368866">
                <Button2 title="BOOK" />
              </a> */}
              <a href="#event-form">
                <Button2 title="BOOK" />
              </a>
            </div>
          </div>
          <div className="card shadow">
            <div className="row">
              <div className="col-md-4 col-4 text-center">
                <img src="img/Events/date.png" />
                <p className="mt-md-4 mt-3"> 21st OCT.2023 </p>
              </div>
              <div className="col-md-4 col-4 text-center">
                <img src="img/Events/time.png" />
                <p className="mt-md-4 mt-3"> 05:00pm</p>
              </div>
              <div className="col-md-4 col-4 text-center">
                <img src="img/Events/location.png" />
                <p className="mt-md-4 mt-3"> Courtyard </p>
              </div>
            </div>
          </div>
          <p>
          Javed Ali is a very well appreciated, prolific Indian Playback Singer. Born and brought up in Delhi. Javed Ali started singing at his very early age with his father Ustad Hamid Hussian.Javed Ali is widely regarded as the versatile golden voic of all time in the Bollywood film industry. Javed Ali is very fortunate singer, as he got to sing in different genre of music like Romantic, Classical, Semi-Classical, Sufi, Ghazal, etc. But “Luck happens, only when preparation meets opportunity”. He is very hard working, pure dedicated and full of determination towards music.
          </p>
          <div className="row my-md-5">
            <div className="col-md-4 px-1 mb-1">
              <img src="img/Events/2.jpg" />
            </div>
            <div className="col-md-4 px-1 mb-1">
              <img src="img/Events/3.jpg" />
            </div>
            <div className="col-md-4 px-1">
              <img src="img/Events/5.jpg" />
            </div>
          </div>
          <div className="tc py-3">
            <h3> Terms and Conditions</h3>
            <ul className="mt-md-4 mt-3">
              <li>
                {" "}
                1. Tickets once booked cannot be exchanged or refunded
              </li>
              <li>
              2. An Internet handling fee per ticket may be levied. Please check the total amount before payment
              </li>
              <li>
              3. We recommend that you arrive at-least 30 minutes prior at the venue for a seamless entry 
              </li>
            </ul>
          </div>
        </Container>
      </Fragment>
    </div>
  );
};

export default EventInfo;
