import React, { Fragment } from "react";
import "./Categories.css";
import { Link } from "react-router-dom";
import Button1 from "../Common/Button/Button1";

function Categories() {
  return (
    <Fragment>
      <div className="categories pt-md-0 pb-md-4 pb-4 mt-xl-4">
        <div className="d-flex pb-md-3 main-pad pe-0">
          <h2> CATEGORIES </h2>
          <hr />
        </div>
        <div className="main-pad">
          <div className="row w-100 mt-md-5 mt-3 ms-0">
            <div className="col-md-4 px-md-0 px-0">
              <a href="/Brandswomenfashion">
                <img className="main_img" src="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/06/MOA-628x541-07.jpg" />
                <div className="category-text">
                  <h3> Fashionista’s Paradise </h3>
                </div>
              </a>
            </div>
            <div className="col-md-8 pe-md-0 mt-md-0 mt-4 ">
              <div className="row w-100 ms-0">
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Allen%20Solly%20Women">
                    <div className="card">
                      <img src="img/Home/Allen Solly woman.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Van%20Heusen%20Women">
                    <div className="card">
                      <img src="img/Home/VHW.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Soch">
                    <div className="card">
                      <img src="img/Home/Soch.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Aurelia">
                    <div className="card">
                      <img src="img/Home/Aurelia.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Biba">
                    <div className="card">
                      <img src="img/Home/BIBA.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Forever%20New">
                    <div className="card">
                      <img src="img/Home/Forever new.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Mango">
                    <div className="card">
                      <img src="img/Home/Mango.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Taneira">
                    <div className="card">
                      <img src="img/Home/Taneria.png" />
                    </div>
                  </a>
                </div>
              </div>
              <a href="/Brandswomenfashion">
                {" "}
                <button> SEE MORE </button>{" "}
              </a>
            </div>
          </div>
          <div className="row w-100 mt-xl-5 pt-md-5 mt-4 ms-0 row-reverse">
            <div className="col-md-8 ps-0">
              <div className="row w-100 ms-0 mt-md-0 mt-4">
                <div className="col-md-3  col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Woodland">
                    <div className="card">
                      <img src="img/Home/woodland.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Sketchers">
                    <div className="card">
                      <img src="img/Home/Skechers.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Hush%20Puppies">
                    <div className="card">
                      <img src="img/Home/hushpuppies.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Hidesign">
                    <div className="card">
                      <img src="img/Home/Hidesign.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Fizzy%20Goblet">
                    <div className="card">
                      <img src="img/Home/Fizzy goblet.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Fitflop">
                    <div className="card">
                      <img src="img/Home/Fitflop.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3  col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Crocs">
                    <div className="card">
                      <img src="img/Home/crocs.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/brand/Bata">
                    <div className="card">
                      <img src="img/Home/Bata.png" />
                    </div>
                  </a>
                </div>
              </div>
              <a href="/Brandsfootfashion">
                {" "}
                <button> SEE MORE </button>{" "}
              </a>
            </div>
            <div className="col-md-4 px-md-0 px-0">
              <a href="/Brandsfootfashion">
                <img className="main_img" src="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/06/MOA-628x541-11.jpg" />
                <div className="category-text">
                  <h3> Walk In Style</h3>
                </div>
              </a>
            </div>
          </div>
          <div className="row w-100 mt-xl-5 mt-4 py-md-5 ms-0">
            <div className="col-md-4 px-md-0 px-0">
              <a href="/Dinefinedine">
                <img className="main_img w-100" src="https://cms.phoenixmallofasia.com/wp-content/uploads/2023/06/MOA-628x541-14.jpg" />
                <div className="category-text">
                  <h3> Dine In Elegance </h3>
                </div>
              </a>
            </div>
            <div className="col-md-8 pe-md-0 mt-md-0 mt-4">
              <div className="row w-100 ms-0">
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/dine/Dobaraa">
                    <div className="card">
                      <img src="img/Home/Dobaraa.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/dine/Ishaara">
                    <div className="card">
                      <img src="img/Home/Ishaara.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/dine/Cha">
                    <div className="card">
                      <img src="https://cms.phoenixmallofasia.com/wp-content/uploads/2024/05/chalogo.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/dine/Cafe%20Allora">
                    <div className="card">
                      <img src="img/Home/Cafe allora.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/dine/Tim%20Hortons">
                    <div className="card">
                      <img src="img/Home/tim hortons.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/dine/Third%20Wave%20Coffee%20Roasters">
                    <div className="card">
                      <img src="img/Home/Third wave coffee.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/dine/Starbucks">
                    <div className="card">
                      <img src="img/Home/starbucks.png" />
                    </div>
                  </a>
                </div>
                <div className="col-md-3 col-6">
                  <a href="https://www.phoenixmallofasia.com/dine/Baskin%20Robbins">
                    <div className="card">
                      <img src="img/Home/i14.png" />
                    </div>
                  </a>
                </div>
              </div>
              <a href="/Dinefinedine">
                {" "}
                <button> SEE MORE </button>{" "}
              </a>
            </div>
          </div>
          <div className="view-btn">
            <a href="/brands">
              {" "}
              <Button1 title="VIEW ALL BRANDS" />
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Categories;
