import React, { Fragment } from "react";
import "./Banner.css";

const Banner = ({ id, title, para, image }) => {
  const addBanner = () => {
    dispatchEvent({
      item: {
        id: id,
        title: title,
        para: para,
        image: image,
      },
    });
  };
  return (
    <div className="banner">
      <Fragment>
        <img src={image} />
        {/* <div className="overlay"></div> */}

        <div className="banner-text main-pad">
          <div className="row">
            <div className="col-md-12">
              <h1>{title}</h1>
              <p className="mt-md-4 mt-2">{para}</p>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default Banner;
