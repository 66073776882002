import React, { Fragment, useState, useEffect } from "react";
import "./Brands.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import LaunchBanner from "../assets/Home/panache1.jpg";
import DineCategorymore from "../components/Dine/DineCategorymore";
import BrandHead from "../components/Brands/BrandHead";
import Form1 from "../components/Common/Form1";
import Dlogo from "../assets/Dine/dlogo.png";
import More from "../components/Common/More";
import { useParams } from 'react-router-dom'; // Import useParams
import axios from 'axios';

const DineDetails = (props) => {
  const { id } = useParams(); // Use useParams to access the 'id' parameter
  const [dine, setDine] = useState(null);


  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`https://admin.phoenixmallofasia.com/dine/${id}`);
        setDine(response.data);
      } catch (error) {
        console.error('Error fetching user data: ' + error.message);
        setDine(null);
      }
    }

    fetchUserData();
  }, [id]);
  return (
    <Fragment>
      <div className="dine_detail">
        <Header />
        <Sidebar />
        {dine && (
          <>
            <Banner
              image={dine.photo2_path}
            />
            <BrandHead
              title={dine.title}
              floor={dine.floor}
              sub1={dine.floor}
              box="RESERVE A TABLE"
              logo={dine.photo1_path}
              subtitle={dine.abouttitle}
              para={dine.about}
              t1={dine.contact}
              t2={dine.email}
              t3={dine.timing}
            />

            <div className="main-pad pe-0">
              <div className="d-flex mt-md-5 mt-3">
                <h2> Additional Information </h2>
                <hr />
              </div>
            </div>
            <More
              className="dine-d-more"
              image1={dine.gallery1_path}
              image2={dine.gallery2_path}
              image3={dine.gallery3_path}
              title1={dine.gallerytitle1}
              title2={dine.gallerytitle2}
              title3={dine.gallerytitle3}
            />
          </>
        )}
        <div className="main-pad pe-0">
          <div className="d-flex mt-md-4 mt-3 mb-md-0 mb-3">
            <h2> More Like This </h2>
            <hr />
          </div>
        </div>
        <DineCategorymore />
        {/* <Nhance /> */}
        <Form1
          title="Have any questions about this restaurant?"
          para="Ask us and we'll be happy to help you!"
        />
      </div>
    </Fragment>
  );
};

export default DineDetails;
