import React from "react";
import "./Location.css";
import { Container } from "react-bootstrap";

const Location = () => {
  return (
    <div className="location py-md-5 py-4">
      <Container className="main-pad py-md-5">
        <div className="row">
          <div className="col-md-6 col-12">
            <iframe
              className="w-100"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.447117626452!2d77.58991617507819!3d13.070825287253754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1925ed3bc12f%3A0x7b731ab2850d5f8b!2sPhoenix%20Mall%20Of%20Asia!5e0!3m2!1sen!2sin!4v1693975974227!5m2!1sen!2sin"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col-md-6 col-12">
            <div className="details p-md-5 p-3">
              <div className="d-flex mb-md-4 mb-3">
                <div>
                  <img src="img/iconaddr.png" />
                </div>
                <div className="text">
                  <h3> ADDRESS</h3>
                  <p>
                    {" "}
                    239/240, Byatarayanapura, Yelahanka, Hobli Yelahanka Taluk,{" "}
                    <br />
                    Bellary Rd, Bengaluru, Karnataka 560092
                  </p>
                </div>
              </div>
              <div className="d-flex mb-md-4 mb-3">
                <div>
                  <img src="img/iconemail.svg" />
                </div>
                <div className="text">
                  <h3> EMAIL</h3>
                  <a href="mailto:contact@mallofasia.com">
                    {" "}
                    contact@mallofasia.com
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <img src="img/iconcall.svg" />
                </div>
                <div className="text">
                  <h3> PHONE</h3>
                  <a href="tel:+91-11-41637217"> +91-11-41637217/18 </a>
                  <a href="tel:+91-9717098995"> +91-9717098995 </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Location;
