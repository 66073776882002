import React, { Fragment } from "react";
import "./responsive.css";
import "./OffersPackages.css";
import Header from "../components/Common/Navbar";
import Sidebar from "../components/Common/NavbarMob";
import Banner from "../components/Common/Banner";
import Form1 from "../components/Common/Form1";
import Card1 from "../components/Common/Card/Card1";

const OfferPackages = (props) => {
  return (
    <div className="offers_packages">
      <Fragment>
        <Header />
        <Sidebar />
        <Banner image="img/Offers/banner.jpg" />
        <div className="main-pad pt-md-5 pt-4">
          <div className="row py-md-5">
            <div className="col-md-3 col-12">
              <Card1
                image={"img/Offers/4.jpg"}
                title="Only At PMOA"
                subtitle="MALABAR Gold And Diamonds"
                para="May 10th 2024"
              />
            </div>
            <div className="col-md-3 col-12">
              <Card1
                image={"img/Offers/5.jpg"}
                title="Only At PMOA"
                subtitle="Senco Gold & Diamonds"
                para="May 10th 2024"
              />
            </div>
            <div className="col-md-3 col-12">
              <Card1
                image={"img/Offers/2.jpg"}
                title="Only At PMOA"
                subtitle="Caratlane"
                para="May 10th 2024"
              />
            </div>
            <div className="col-md-3 col-12">
              <Card1
                image={"img/Offers/3.jpg"}
                title="Only At PMOA"
                subtitle="ORRA"
                para="May 10th 2024"
              />
            </div>
            <div className="col-md-3 col-12">
              <Card1
                image={"img/Offers/1.jpg"}
                title="Only At PMOA"
                subtitle="SHAYA"
                para="May 10th 2024"
              />
  </div>
          </div>
        {/*</div>
        <div className="main-pad pe-0">
          <div className="d-flex">
            <h2> PACKAGES </h2>
            <hr />
          </div>
        </div>
        <div className="main-pad">
          <div className="row py-md-5 my-md-4 mt-4">
          <div className="col-md-3 col-12">
              <Card1
                image={"img/Offers/6.jpg"}
                title="Only At MOA"
                subtitle="Getting new offers to you"
                para="Coming Soon"
              />
            </div>
            <div className="col-md-3 col-12">
              <Card1
                image={"img/Offers/6.jpg"}
                title="Only At MOA"
                subtitle="Getting new offers to you"
                para="Coming Soon"
              />
            </div>
            <div className="col-md-3 col-12">
              <Card1
                image={"img/Offers/6.jpg"}
                title="Only At MOA"
                subtitle="Getting new offers to you"
                para="Coming Soon"
              />
            </div>
            <div className="col-md-3 col-12">
              <Card1
                image={"img/Offers/6.jpg"}
                title="Only At MOA"
                subtitle="Getting new offers to you"
                para="Coming Soon"
              />
            </div>
  </div>*/}
        </div>
        <Form1
          title="Have a question? Connect with us!"
          para="Write to us and we will reach out shortly."
        />
      </Fragment>
    </div>
  );
};

export default OfferPackages;
