import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BsSearch } from "react-icons/bs";
import "./Navbar.css";
import Search from "./SearchBar";

function SearchModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="search_sector">
        <Button variant="" onClick={handleShow}>
          <BsSearch />
        </Button>

        <Modal className="search_modal" show={show} onHide={handleClose}>
          <Modal.Header className="border-0" closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Search />{" "}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default SearchModal;
