// import React from "react";
// import {
//   CDBSidebar,
//   CDBSidebarContent,
//   CDBSidebarHeader,
//   CDBSidebarMenu,
//   CDBSidebarMenuItem,
// // } from "cdbreact";
// import Logo from "../../assets/moa-logo.png";
// import NavDropdown from "react-bootstrap/NavDropdown";
// import "./Navbar.css";
// import { NavLink } from "react-router-dom";
// import { Navbar } from "react-bootstrap";

// const Sidebar = () => {
//   return (
//     <div className="navbar-mobile">
//       <div className="d-block d-sm-none">
//         <div style={{ display: "flex", overflow: "scroll initial" }}>
//           <a href="/">
//             <div className="logo">
//               <img src={Logo} />{" "}
//             </div>
//           </a>
//           <CDBSidebar textColor="#fff" backgroundColor="#333">
//             <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
//               <a
//                 href="/"
//                 className="text-decoration-none"
//                 style={{ color: "inherit" }}
//               >
//                 <img src={Logo} />{" "}
//               </a>
//             </CDBSidebarHeader>

//             <CDBSidebarContent className="sidebar-content">

//               <CDBSidebarMenu>
//                 <NavLink exact to="/launch" activeClassName="activeClicked">
//                   <CDBSidebarMenuItem>Newly Launched</CDBSidebarMenuItem>
//                 </NavLink>
//                 <NavLink exact to="/brands" activeClassName="activeClicked">
//                   <CDBSidebarMenuItem>Brands</CDBSidebarMenuItem>
//                 </NavLink>
//                 <NavLink exact to="/dine" activeClassName="activeClicked">
//                   <CDBSidebarMenuItem>Dine</CDBSidebarMenuItem>
//                 </NavLink>
//                 <NavLink exact to="/luxury" activeClassName="activeClicked">
//                   <CDBSidebarMenuItem>Luxury</CDBSidebarMenuItem>
//                 </NavLink>
//                 <NavLink exact to="/leisure" activeClassName="activeClicked">
//                   <CDBSidebarMenuItem>Leisure</CDBSidebarMenuItem>
//                 </NavLink>
//                 <NavLink exact to="/services" activeClassName="activeClicked">
//                   <CDBSidebarMenuItem>Services</CDBSidebarMenuItem>
//                 </NavLink>
//                 <NavLink exact to="/sign-up" activeClassName="activeClicked">
//                   <CDBSidebarMenuItem>SignUp</CDBSidebarMenuItem>
//                 </NavLink>
//               </CDBSidebarMenu>

//             </CDBSidebarContent>
//           </CDBSidebar>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;

import React from "react";
import Logo from "../../assets/moa-logo.png";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./Navbar.css";
import SignUp from "../../pages/SignUp";
import {
  BiLogoFacebook,
  BiLogoTwitter,
  BiLogoInstagram,
  BiLogoYoutube,
  BiMap,
  BiLogoLinkedinSquare,
} from "react-icons/bi";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";

class MenuButton extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var containerClasses = ["container"];
    if (!this.props.state) {
      containerClasses.push("close");
    }
    return (
      <div className="navbar-mobile d-block d-sm-none">
        <div className="d-flex">
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            <img src={Logo} />{" "}
          </a>
          <span className={containerClasses.join(" ")}>
            <div className={"bar1"}></div>
            <div className={"bar2"}></div>
            <div className={"bar3"}></div>
          </span>
        </div>
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  ChangeNav = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };
  render() {
    var containerClasses = [];
    if (this.state.open) {
      containerClasses.push("open");
    }
    return (
      <div className="navbar-mobile d-block d-sm-none">
        <div className={containerClasses.join(" ")}>
          <div className={"menubutton"} onClick={() => this.ChangeNav()}>
            <MenuButton state={!this.state.open} />
          </div>

          <div className={"sidenav"}>
            <Navbar>
              <NavDropdown
                title="Phoenix Mall of Asia, Bangalore"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  className=""
                  href="https://www.phoenixmarketcity.com/mumbai"
                  target="_blank"
                >
                  Pheonix Marketcity, Mumbai
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://phoenixpalladium.com/"
                  target="_blank"
                >
                  Pheonix Palladium, Mumbai
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.phoenixmarketcity.com/pune"
                  target="_blank"
                >
                  Pheonix Marketcity, Pune
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.phoenixmarketcity.com/chennai"
                  target="_blank"
                >
                  Pheonix Marketcity, Chennai
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.phoenixmarketcity.com/chennai"
                  target="_blank"
                >
                  Pheonix Palladium, Chennai
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.phoenixmarketcity.com/bangalore"
                  target="_blank"
                >
                  Pheonix Marketcity, Bangalore
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.phoenixunited.in/lucknow"
                  target="_blank"
                >
                  Pheonix United, Lucknow
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.phoenixpalassio.com/"
                  target="_blank"
                >
                  Pheonix Palassio, Lucknow
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.phoenixunited.in/bareilly"
                  target="_blank"
                >
                  Pheonix United, Bareilly
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.palladiumahmedabad.com/"
                  target="_blank"
                >
                  Pheonix Palladium, Ahmedabad
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.phoenixcitadel.in/"
                  target="_blank"
                >
                  Pheonix Citadel, Indore
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.thephoenixmills.com/"
                  target="_blank"
                >
                  Corporate Website
                </NavDropdown.Item>
              </NavDropdown>
            </Navbar>
            {/*<a href="/launch">MOA ARRIVALS</a>*/}
            <a href="/brands">BRANDS</a>
            <a href="/dine">DINE</a>
            {/* <a href="/luxury">LUXURY</a> */}
            <a href="/leisure">LEISURE</a>
            <a href="/services">SERVICES</a>
            {/* <a href="https://events.phoenixmallofasia.com/the-great-electronics-festival/">PHOENIX ELECTRONICS FESTIVAL</a>  */}
            <SignUp />
            <div class="social-media d-flex mt-md-0 mt-xl-0">
              <a
                href="https://www.facebook.com/PhoenixMallOfAsiaBangalore"
                target="_blank"
              >
                {" "}
                <BiLogoFacebook />{" "}
              </a>
              <a href="https://twitter.com/MOA_Bangalore" target="_blank">
                {" "}
                <img src="img/twitter.png" width="50" />
              </a>
              <a href="https://www.instagram.com/phoenixmoa/" target="_blank">
                {" "}
                <BiLogoInstagram />
              </a>
              <a
                href="https://www.linkedin.com/company/phoenix-mall-of-asia"
                target="_blank"
              >
                {" "}
                <FaLinkedinIn />
              </a>
              <a
                href="https://www.youtube.com/channel/UCzm595WE5W0hoFZgyX0L1ig"
                target="_blank"
              >
                {" "}
                <AiOutlineYoutube />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
